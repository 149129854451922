button {
    transition: all .2s;
    text-transform: capitalize;
}

.md-button,
.md-raised {
    font-size: 12px;
    min-height: auto;
    font-weight: 500;
    letter-spacing: .7px;
    box-shadow: none !important;
    text-transform: none;
    margin: 0;
    justify-content: center;

    .md-icon-button {
        margin: 0;
        padding: 0;
    }

    .md-ripple-container {
        display: none;
    }

    &.primary-button,
    &.primary-2-button,
    &.secondary-button,
    &.secondary-2-button {
        min-height: 44px;
        max-height: 44px;
        padding: 5px 10px;
        border-style: solid;
        border-width: 2px;
        border-radius: 5px;
        line-height: 20px;
        //font-weight: 700;

        .svg-icon {
            width: 20px;
            height: 20px;
            vertical-align: bottom;
        }

        &:disabled {
            cursor: none;
            pointer-events: none;
        }
    }

    &.primary-button,
    &.primary-2-button {
        color: #FFFFFF;

        .svg-icon {
            fill: #FFFFFF;
        }
    }

    &.primary-button {
        border-color: $PrimaryButtonColor;
        background-color: $PrimaryButtonColor;

        &:not([disabled]) {
            &:hover,
            &.md-focused {
                border-color: $PrimaryButtonHoverColor;
                background-color: $PrimaryButtonHoverColor;
            }

            &:active {
                border-color: $PrimaryButtonActiveColor;
                background-color: $PrimaryButtonActiveColor;
            }
        }

        &:disabled {
            background-color: $PrimaryButtonDisabledColor;
            border-color: $PrimaryButtonDisabledColor;
            color: #FFFFFF;
        }
    }

    &.primary-2-button {
        border-color: $Primary2ButtonColor;
        background-color: $Primary2ButtonColor;

        &:not([disabled]) {
            &:hover,
            &.md-focused {
                border-color: $Primary2ButtonHoverColor;
                background-color: $Primary2ButtonHoverColor;
            }

            &:active {
                border-color: $Primary2ButtonActiveColor;
                background-color: $Primary2ButtonActiveColor;
            }
        }

        &:disabled {
            background-color: $Primary2ButtonDisabledColor;
            border-color: $Primary2ButtonDisabledColor;
            color: #FFFFFF;
        }
    }

    &.secondary-button {
        background-color: $InputBackgroundColor;
        border-color: $SecondaryButtonColor;
        color: $SecondaryButtonColor;

        .svg-icon {
            fill: $SecondaryButtonColor;
        }

        &:not([disabled]) {
            &:hover,
            &.md-focused {
                border-color: $Secondary2ButtonHoverColor;
                color: $Secondary2ButtonHoverColor;

                svg {
                    fill: $Secondary2ButtonHoverColor;
                }
            }

            &:active {
                border-color: $Secondary2ButtonActiveColor;
                color: $Secondary2ButtonActiveColor;

                svg {
                    fill: $Secondary2ButtonActiveColor;
                }
            }
        }

        &:disabled {
            border-color: $SecondaryButtonDisabledColor;
            color: $Secondary2ButtonTextColor;

            svg {
                fill: $SecondaryButtonDisabledColor;
            }
        }
    }

    &.secondary-2-button {
        background-color: $InputBackgroundColor;
        border-color: $Secondary2ButtonColor;
        color: $Secondary2ButtonTextColor;

        .svg-icon {
            fill: $Secondary2ButtonTextColor;
        }

        &:not([disabled]) {
            &:hover,
            &.md-focused {
                border-color: $Secondary2ButtonHoverColor;
                color: $Secondary2ButtonHoverColor;
                background-color: $InputBackgroundColor;

                svg {
                    fill: $Secondary2ButtonHoverColor;
                }
            }

            &:active {
                border-color: $Secondary2ButtonActiveColor;
                color: $Secondary2ButtonActiveColor;
                background-color: $InputBackgroundColor;

                svg {
                    fill: $Secondary2ButtonActiveColor;
                }
            }
        }

        &:disabled {
            border-color: $Secondary2ButtonDisabledColor;
            color: $Secondary2ButtonTextColor;

            svg {
                fill: $Secondary2ButtonDisabledColor;
            }
        }
    }
}

a.secondary-button,
a.primary-2-button {
    margin-left: 15px; //TODO remove this on btn "Export ..."
}

.back-button,
.back-link {
    border-radius: 5px;
    border-color: $Secondary2ButtonColor;
    color: $Secondary2ButtonTextColor;
    background-color: $InputBackgroundColor;
    border-width: 2px;

    svg {
        width: 21px;
        height: 21px;
        fill: $Secondary2ButtonTextColor;
    }

    &:hover {
        border-color: $Secondary2ButtonHoverColor;
        color: $Secondary2ButtonHoverColor;

        svg {
            fill: $Secondary2ButtonHoverColor;
        }
    }

    &:active {
        border-color: $Secondary2ButtonActiveColor;
        color: $Secondary2ButtonActiveColor;

        svg {
            fill: $Secondary2ButtonActiveColor;
        }
    }

    &:disabled {
        border-color: $Secondary2ButtonColor;
        color: $Secondary2ButtonTextColor;
        cursor: none;
        pointer-events: none;

        svg {
            fill: $Secondary2ButtonTextColor;
        }
    }
}

.back-link {
    background: #fff;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;
    display: inline-block;
    font-size: 14px;
    min-height: auto;
    line-height: 22px;
    text-align: center;
    width: 120px;
    padding: 10px 12px;
}

