.dashboard-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    md-input-container {
        padding: 0;
    }

    .label {
        margin-right: 12px;
    }

    .ember-power-select-trigger {
        min-width: 180px;
    }

    .dates-selector-wrapper {
        align-items: center;
        display: flex;
        margin-left: 30px;
    }
}

.dashboard-page {
    padding: 3px 0;

    > div {
        justify-content: space-between;
        align-items: center;
    }

    .label {
        margin-right: 12px;
    }

    .wrap-start-note-block {
        display: flex;
        margin-bottom: 20px;
        justify-content: center;

        > div {
            align-items: center;
            border-radius: 8px;
            border: 1px solid #ff9900;
            display: flex;
            width: auto;
            padding: 7px;
        }

        .info-icon {
            height: 24px;
            fill: #ff9900;
            margin-right: 8px;
            width: 24px;
        }
    }

    .satisfaction-row {
        display: flex;

        .svg-icon {
            margin-left: 5px;
            fill: #219653;
        }

        .like, .dislike, .rating {
            width: 30px;
        }

        .dislike-icon {
            fill: #e24b4b;
            transform: rotateX(180deg);
        }

        .rating {
            color: #219653;
        }
    }

    h4 {
        font-size: 14px;
        margin: 7px 14px 10px;
        text-align: left;
        font-weight: 500;
        color: #121416;
    }

    .highcharts-title {
        font-size: 14px !important;
        font-weight: 500 !important;
        color: #121416 !important;
        fill: currentColor !important;
    }

    .row-panels {
        display: flex;
        margin: 0 0 15px;

        > div {
            border-radius: 2px;
            margin: 0 15px;
            box-shadow: 0 0 4px 2px rgba(0, 0, 0, .08);
            text-align: center;
            flex: 1;

            > div {
                padding: 10px;
            }

            div.svg-place {
                display: flex;
                justify-content: center;

                > div {
                    width: 54px;
                    height: 54px;
                    padding: 7px;
                    border-radius: 50%;
                    background: #EFFAFF;

                    > svg {
                        width: 40px;
                        height: 40px;
                    }
                }
            }

            h3 {
                border-bottom: 3px solid #eee;
                margin: 0 0 10px;
                font-size: 14px;
                font-weight: 500;
                color: #606a73;
                padding: 0 0 10px;
                text-transform: uppercase;
            }

            p {
                margin: 5px 0 7px;
                font-weight: bold;
            }
        }

        > div:first-child {
            margin-left: 0;
        }

        div:last-child {
            margin-right: 0;
        }

        .total-diff {
            color: red;

            .paper-icon {
                color: red;
                min-width: 35px !important;
                width: 35px;
            }

            &._green {
                color: green;

                .paper-icon {
                    color: green;
                }
            }
        }
    }

    .table {
        thead {
            th {
                text-align: center;
            }

            tr {
                border-bottom: 1px solid #eee;
            }
        }

        th, td {
            text-align: center;

            &:first-child {
                text-align: left;
            }
        }
    }
}

.dates-custom-picker {
    right: 0;
    position: absolute;
    z-index: 1;

    .flatpickr-input {
        display: none;
    }
}

.quick-responses-wrap {
    table {
        width: 100%;

        td, th {
            text-align: left;
            padding: 10px;
        }

        th {
            font-size: 14px;
            font-weight: 500;
            color: #121416;
            text-transform: none;
        }

        tr {
            transition: .5s;
        }
    }

    .show-all {
        border-bottom: 1px solid;
        cursor: pointer;
        display: inline-block;
        margin-top: 10px;
    }
}

.highcharts-credits {
    display: none !important;
}
