.dialog-icon-warning {
    margin-right: 20px;
    width: 24px;
    height: 24px;
    fill: white;
}

.warning-dialog {
    min-width: 532px;
    max-width: 532px;

    md-toolbar {
        height: 80px;
        min-height: 80px;
        max-height: 80px;
        padding: 20px;

        .md-toolbar-tools {
            padding: 0;
        }

        .svg-icon {
            margin-right: 20px;
            width: 24px;
            height: 24px;
            fill: white;
        }
    }

    .md-dialog-content {
        padding: 27px 45px 0 45px;

        .svg-icon {
            &.blue {
                fill: $AdminMenuBlueColor;
            }
        }
    }

    md-dialog-actions {
        display: flex;
        justify-content: space-between;
        padding: 35px 45px;

        .md-button {
            margin: 0;
        }

        .primary-button {
            background-color: #2b78e4;
            border-color: #2b78e4;
        }
    }
}
