.popup {
    left: auto !important;
    right: 0 !important;
    position: fixed !important;

    md-content {
        color: #989a9f;
    }

    .ember-paper-autocomplete-search-input {
        margin: 0;
        padding: 0 3px;
    }

    md-toolbar {
        height: auto;
        max-height: 70px;

        .md-toolbar-tools {
            font-weight: 500;
            //justify-content: space-between;

            .close-btn {
                position: absolute;
                right: 35px;
                top: 20px;
                margin: 0;
            }

            .svg-icon {
                height: 30px;
                margin-right: 10px;
                width: 30px;
            }
        }
    }

    .create-bot-btn, .line-connect-btn {
        background-color: #2B78E4;
        border-radius: 5px;
        color: #fff;
        cursor: pointer;
        text-align: center;
        width: 50%;

        a {
            color: #fff;
            display: block;
            padding: 12px 5px;
        }
    }

    .line-connect-btn {
        padding: 12px 5px;
    }

    .title-channel {
        padding: 10px;
        border-bottom: 1px solid #eee;
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .svg-icon {
            width: 20px;
            height: 20px;
            display: inline-block;
            margin-right: 12px;
        }

        span {
            letter-spacing: .7px;
            text-transform: uppercase;
        }
    }

    .link-facebook {
        margin-left: 20px;
    }

    .mailbox-server {
        background: rgba(0, 0, 0, .05);
        border-radius: 5px;
        padding: 10px 20px;
        margin-bottom: 10px;

        .label {
            margin-bottom: 5px;
            font-weight: 500;
        }

        md-radio-button {
            margin: 0;
        }

        .radio-group {
            display: flex;
            justify-content: space-between;

            .md-label {
                margin-bottom: 2px;
            }
        }
    }

    .whitelist-ip {
        margin: 0 15px;
        width: 70px;
    }

    .copy-btn {
        background-color: #2B78E4;
        border-radius: 5px;
        color: #fff;
        cursor: pointer;
        font-weight: 700;
        font-size: 12px;
        height: 100%;
        line-height: 42px;
        margin-left: 10px;
        text-align: center;
        width: 82px;

        &:hover {
            //background-color: #b6a8a8;
        }
    }

    md-input-container {
        &:after {
            display: none !important;
        }
    }

    md-content {
        overflow: visible;
        padding: 0;
        margin: 0 45px;
        flex: 1;
    }

    form {
        display: flex;
        flex-direction: column;
        height: 100%;
        position: relative;
        margin-top: 16px;
    }

    &.small {
        width: 30%;
        min-width: 30%;
        max-width: 30%;
    }

    &.medium {
        width: 40%;
        min-width: 40%;
        max-width: 40%;
    }

    &.large {
        width: 60%;
        min-width: 60%;
        max-width: 60%;
    }

    .close-button {
        top: 10px;
        right: 15px;
        position: absolute;
    }

    .remove-button {
        cursor: pointer;
        margin-top: 10px;
    }

    .popup-footer {
        display: flex;
        justify-content: space-between;
        padding: 42px 45px 32px 45px;
        background-color: #fff;

        .md-button {
            margin: 0;
        }

        .primary-button,
        .secondary-button {
            margin-left: 10px;
        }

        .secondary-2-button {
            &._back {
                margin-left: 10px;
            }
        }
    }

    md-stepper {
        height: 100%;
    }

    md-stepper {
        height: 100%;
    }

    .md-steppers {
        .popup-footer {
            padding: 0 0 15px !important;
        }
    }

    &.create-popup {
        width: 465px;
        min-width: 465px;
        max-width: 465px;
    }
}
