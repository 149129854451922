.login-wrapper, .sign-up-wrapper, .forgot-wrapper, .set-password-wrapper {
    position: relative;

    *, ::after, ::before {
        box-sizing: border-box;
    }

    &._register {
        text-align: center;
        display: flex;
        height: 100vh;

        h4, p {
            line-height: 1.5;
            letter-spacing: .4px;
        }

        .item {
            margin: auto;

            img {
                max-width: 100%;
            }
        }
    }

    .content {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .wrap-item {
        flex: 1;
        display: flex;
        justify-content: center;
    }

    ._logo {
        position: absolute;
        left: 4px;
        top: 4px;

        img {
            width: 65px;
            height: 65px;
        }
    }

    h2 {
        margin-bottom: 30px;
        text-align: center;
        font-weight: 500;
        font-size: 20px;
        color: #9e9e9e;
    }

    ._image {
        flex: 1;

        img {
            width: 100%;
            height: 100%;
            max-width: 70%;
        }
    }

    .flex-row {
        display: flex;

        .form-group:first-child {
            margin-right: 15px;
        }
    }

    ._separator {
        color: #9e9e9e;
        position: relative;
        border-bottom: 1px solid grey;
        margin: 30px 0;
        text-align: center;

        span {
            position: absolute;
            top: -9px;
            left: 0;
            right: 0;
            width: 60px;
            margin: auto;
            background-color: #fff;
        }
    }

    .btn-google {
        align-items: center;
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .action-sign {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        font-size: 12px;

        a {
            font-weight: 500;
        }
    }

    .form-group {
        position: relative;

        .svg-icons {
            top: 32px;
            padding: 0;
            left: 10px
        }

        md-input-container {
            display: block;
            padding: 0;
        }

        .md-input {
            padding: 0 10px 0 40px !important;
            height: 42px;
            background: #fff;
            border: 1px solid #9e9e9e!important;
            border-radius: 5px;
        }
    }

    .eye-icon, .checkmark-circle {
        height: 20px;
        width: 20px;
    }

    .svg-icon-wrap {
        cursor: pointer;
        position: absolute;
        left: 10px;
        top: 12px;
    }

    .validation-container {
        .svg-icon {
            margin-right: 7px;
        }

        .item-validate {
            margin-bottom: 12px;
            display: flex;
            align-items: center;

            &.valid {
                color: #54a702;

                .svg-icon {
                    fill: #54a702;
                }
            }
        }
    }

    .item {
        max-width: 400px;
        width: 70%;

        p {
            font-size: 14px;
            margin-bottom: 5px;
        }
    }

    .person-icon, .email-icon {
        height: 16px;
        width: 16px;
    }
}

.forgot-wrapper {
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;

    .intro-image {
        margin: 0 auto 20px;
        width: 70%;

        img {
            max-width: 100%;
        }
    }
}

.sign-up-wrapper .content {
    .action-sign {
        display: block;
    }

    .item {
        max-width: 100%;
        width: auto;
    }
}
