$BodyColor: #FFFFFF;

$MainMenuBlueColor: #1E20A8;

$AdminMenuBlueColor: #2B78E4;
$AdminMenuHoverColor: #EFFAFF;
$TextBlackColor: #231F20;
$TextGreyColor: #5D5E64;
$TextLightGreyColor: #989a9f;

$PrimaryButtonColor: $MainMenuBlueColor;
$PrimaryButtonHoverColor: #3437D6;
$PrimaryButtonActiveColor: #171884;
$PrimaryButtonDisabledColor: #C4C4C4;

$Primary2ButtonColor: $AdminMenuBlueColor;
$Primary2ButtonHoverColor: $PrimaryButtonHoverColor;
$Primary2ButtonActiveColor: $PrimaryButtonActiveColor;
$Primary2ButtonDisabledColor: $PrimaryButtonDisabledColor;

$SecondaryButtonColor: $Primary2ButtonColor;
$SecondaryButtonHoverColor: #4A96FF;
$SecondaryButtonActiveColor: #306FC9;
$SecondaryButtonDisabledColor: $PrimaryButtonDisabledColor;

$Secondary2ButtonColor: #DADADA;
$Secondary2ButtonHoverColor: #4A96FF;
$Secondary2ButtonActiveColor: $SecondaryButtonActiveColor;
$Secondary2ButtonDisabledColor: $PrimaryButtonDisabledColor;
$Secondary2ButtonTextColor: #ADADAD;

$InputBackgroundColor: #FFFFFF;
$InputBorderColor: #EEECEC;

$LabelColor: #989A9F;
$LinkColor: #007BFF;

$TitleColor: #4d4c4f;

