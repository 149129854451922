.align-middle {
    vertical-align: middle;
}

.widget-settings-fields {
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    padding-top: 15px;
    margin-top: 15px !important;
    width: 100%;
    padding-bottom: 15px;
    margin-bottom: 15px !important;
    background-color: #fafafa;

    h2 {
        padding-left: 15px;
        font-size: 16px;
        font-weight: 400;
        margin: 0 !important;
    }

    .enable-switch {
        float: right;
    }
}

.transition-loader {
    background-color: rgba(0, 0, 0, .75);
    position: absolute;
    top: 0;
    color: #fff;
    left: 0;
    height: 100%;
    text-align: center;
    width: 100%;
    z-index: 2;

    .transition-loader-text {
        font-size: 16px;
        top: 50%;
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
    }
}

.transition-loader-inner {
    top: 60%;
    position: absolute;
    padding: 0 100px;
    left: 0;
    right: 0;

    label {
        border-radius: 50%;
        margin: 0 10px;
        opacity: 0;
        padding: 0;
        display: inline-block;
        background: #fff;
        width: 10px;
        height: 10px;
    }
}

$key-frames-ms: 600ms, 500ms, 400ms, 300ms, 200ms, 100ms;

@each $current-ms in $key-frames-ms {
    $i: index($key-frames-ms, $current-ms);
    .transition-loader-inner label:nth-child(#{$i}) {
        -webkit-animation: loader 3s $current-ms infinite ease-in-out;
        animation: loader 3s $current-ms infinite ease-in-out;
    }
}

@keyframes loader {
    0% {
        opacity: 0;
        transform: translateX(-300px) scale(1);
    }
    33% {
        opacity: 1;
        transform: translateX(0px) scale(1.5);
    }
    66% {
        opacity: 1;
        transform: translateX(0px) scale(1);
    }
    100% {
        opacity: 0;
        transform: translateX(300px) scale(1.5);
    }
}

@-webkit-keyframes loader {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-300px);
    }
    33% {
        opacity: 1;
        -webkit-transform: translateX(0px);
    }
    66% {
        opacity: 1;
        -webkit-transform: translateX(0px);
    }
    100% {
        opacity: 0;
        -webkit-transform: translateX(300px);
    }
}

.alignCenter {
    align-items: center;
    display: flex;
}

.alignCenterAll {
    align-items: center;
    justify-content: center;
    display: flex;
}

.flexDisplay {
    display: flex;
}

.emptyList {
    left: 0;
    right: 0;
    position: absolute;
    top: 41%;
    text-align: center;

    .svg-icon {
        height: 35px;
        width: 35px;
    }

    p {
        font-size: 18px;
        margin: 10px 0 0;
        line-height: 32px;
    }

    .openBtn {
        cursor: pointer;

        &:hover {
            border-bottom: 1px solid;
        }
    }
}

.sortButton {
    padding: 0 !important;
    margin: 0 !important;
    background-color: transparent !important;

    &.md-button.md-default-theme:not([disabled]).md-focused, .md-button:not([disabled]).md-focused {
        background-color: transparent !important;
    }

    md-icon {
        padding: 0 !important;
        padding-top: 2px !important;
        padding-right: 10px !important;
    }

    .paper-icon {
        background: none !important;
    }

    &.md-focused {
        background-color: transparent;
    }
}

.lds-ellipsis {
    position: relative;
    width: 64px;
    height: 64px;
    margin: 0 auto;

    div {
        position: absolute;
        top: 18px;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background: #007bff;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);

        &:nth-child(1) {
            left: 6px;
            animation: lds-ellipsis1 0.6s infinite;
        }

        &:nth-child(2) {
            left: 6px;
            animation: lds-ellipsis2 0.6s infinite;
        }

        &:nth-child(3) {
            left: 26px;
            animation: lds-ellipsis2 0.6s infinite;
        }

        &:nth-child(4) {
            left: 45px;
            animation: lds-ellipsis3 0.6s infinite;
        }
    }
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(19px, 0);
    }
}

.custom-toast {
    background-color: #FFFADE;
    box-shadow: 0 1px 5px 1px rgba(0, 0, 0, .3);
    position: fixed;
    padding: 20px;
    right: 0;
    top: 0;
    text-align: center;
    width: 100%;
    z-index: 10;

    .md-toast-content {
        background: #fffbdd;
        color: #333;
        max-width: 100%;
        height: 60px;
        justify-content: center;
    }

    .md-button {
        margin: 0 15px;
    }
}

.role-details {
    .form-group {
        width: 400px;
    }

    .title-role {
        margin-top: 20px;

        .selector {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .ember-power-select-trigger {
                margin-left: 10px;
                max-width: 200px;
            }
        }
    }

    table {
        tbody {
            tr {
                td:first-child {
                    width: 100%;
                }
            }
        }

        .remove-btn {
            border-bottom: 1px solid transparent;
            cursor: pointer;
            display: flex;
            align-items: center;
            width: 80px;

            &:hover {
                border-color: #121416;
                color: #121416;
                font-weight: 500;

                .svg-icon {
                    fill: #121416 !important;
                }
            }

            .svg-icon {
                margin-right: 3px;
            }
        }
    }
}

.title-permission, .title-marketplace {
    display: flex;

    .ember-power-select-trigger {
        min-width: 180px;
        max-width: 320px;
    }
}

.permission-tabs {
    height: 50px;
    display: flex;
    margin-bottom: 15px;

    .permission-tab {
        border-bottom: 2px solid transparent;
        font-size: 14px;
        text-align: center;
        line-height: 24px;
        padding: 12px 24px;
        cursor: pointer;
        white-space: nowrap;
        position: relative;
        font-weight: 500;
        box-sizing: border-box;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: .3s;
        text-transform: uppercase;

        &.selected {
            border-bottom-color: $Primary2ButtonColor;
            color: $Primary2ButtonColor;
        }
    }
}

.permission-table {
    th {
        text-transform: none;
    }

    td:first-child {
        color: #121416 !important;
        font-weight: 500;
    }

    thead tr th:not(:first-child), tbody tr td:not(:first-child) {
        text-align: center;
    }

    md-icon {
        &.active {
            color: green;
        }
    }

    md-checkbox[disabled]:not(.md-checked) .md-icon {
        border-color: #ddd;

        &:after {
            border-color: #ddd;
        }
    }
}

.emberTagInput {
    margin: 0;
    padding: 5px 5px 2px 5px;
    border: 1px solid lightgray;
    cursor: text;
}

.emberTagInput-tag,
.emberTagInput-input {
    font: 12px sans-serif;
    padding: 5px 10px;
    margin-bottom: 3px;
}

.emberTagInput-tag {
    background: cornflowerblue;
    border-radius: 20px;
    margin-right: 5px;
    color: white;
}

.emberTagInput-tag--remove {
    opacity: 0.75;
}

.emberTagInput-remove:before {
    content: 'x';
    cursor: pointer;
}

.emberTagInput-input {
    border: none;
    padding-left: 0;
}

.emberTagInput-input:focus {
    outline: none;
}

.emberTagInput-input.is-disabled {
    display: none;
}

.user-status-active {
    color: green;
}

.user-status-inactive {
    color: grey;
}

.user-status-pending {
    color: orange;
}

.create-group-form {
    .selected-list {
        .item {
            display: inline-block;
            cursor: pointer;
            margin: 0 5px 5px 0;
            padding: 2px 4px;

            &:hover {
                background-color: #e7e7e7;
            }

            .svg-icon {
                height: 20px;
                width: 20px;
                margin-right: 5px;
            }
        }
    }
}

.escalation-info {
    margin-bottom: 96px;
}

.user-details,
.group-details,
.company-details,
.escalation-details {
    div.title {
        font-size: 16px;
        font-weight: 500;
        color: $TextBlackColor;
    }

    svg {
        width: 20px;
        height: 20px;
        margin-right: 9px;
        vertical-align: text-bottom;
    }

    .user-information {
        > div {
            margin: 5px;
        }
    }

    .user-data {
        > div {
            margin: 10px;
        }

        .form-group {
            margin-bottom: 5px;
        }

        .editable {
            margin-left: 34px;
        }

        .reset-pass-action {
            margin-top: 30px;
        }
    }

    .access-information {
        flex: 10;
        overflow-y: auto;

        .access-item {
            margin-bottom: 30px;
        }

        .custom-roles {
            flex: 20;

            .roles-list {
                .secondary-2-button {
                    margin-left: 10px;
                }
            }
        }

        h4 {
            color: #121416;
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
            margin-bottom: 7px;

            svg {
                width: 24px;
                height: 24px;
                vertical-align: middle;
            }
        }

        h5 {
            color: #121416;
            font-size: 13px;
            font-weight: 700;
            margin-bottom: 7px;
        }
    }

    .about-company {
        .form-group {
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .created {
        margin-bottom: 10px;
    }

    .wrap-columns {
        display: flex;
        margin-top: 30px;
    }

    .column-table {
        flex: 1;
        flex-direction: column;

        &:first-child {
            margin-right: 20px;
        }
    }

    .editable {
        //font-weight: 500;

        .link {
            color: $TextBlackColor !important;
        }

        *:not(.ember-power-select-status-icon) {
            border: none !important;
        }

        &.title {
            * {
                font-size: 16px !important;
            }
        }

        .ember-power-select-trigger {
            margin-left: -10px;

            &:hover {
                background-color: #e7e7e7;
            }
        }
    }


    .cp-Panel-body {
        .editable {
            margin-left: 28px !important;
        }
    }

    div.editable {
        //padding-top: 12px;
    }

    .table-wrapper {
        max-height: 100%;
        overflow: auto;

        th {
            text-transform: none !important;
            font-size: 14px !important;
            color: #121416 !important;
        }

        td {
            color: $TextBlackColor !important;
        }

        a.link {
            white-space: nowrap;
        }

        tbody {
            tr:nth-child(odd) {
                background-color: #eee;
            }
        }

        .table tbody td {
            line-height: 30px;

            .mini-avatar, .md-avatar {
                margin-right: 5px;
            }
        }
    }

    table {
        tbody {
            tr {
                td:first-child {
                    width: 100%;
                }
            }
        }
    }
}

.group-details,
.company-details,
.escalation-details {
    max-height: 100%;

    input,
    textarea {
        border: none;
    }

    .lists,
    .content {
        flex: 20;
    }

    .selector {
        margin-top: 10px;

        .ember-power-select-trigger {
            width: 50%;
        }
    }
}

.group-details {
    .form-group {
        md-input-container {
            min-width: 350px;
            width: auto;
        }
    }
}

.escalation-details {
    .content {
        overflow-y: auto;

        .column:first-child {
            margin-right: 15px;
        }
    }

    .page-title {
        flex-direction: row;

        .md-button {
            display: inline-block;
        }
    }
}

.company-details {
    .channel-item {
        padding: 5px;
    }

    .timezone {
        align-items: center;
        display: flex;

        .ember-basic-dropdown-trigger {
            width: 325px;
        }
    }

    .column {
        overflow: auto;

        &.flex-40 {
            margin-right: 15px;
        }

        .company-info-wrap {
            margin-bottom: 20px;

            .profile_upload {
                margin-right: 10px;
            }

            .avatar-upload {
                margin-bottom: 0;
            }

            .info {
                > div {
                    margin: 5px;
                }
            }
        }

        .arrow-icon {
            transition: .2s;
            transform: rotate(90deg);
            height: 16px;
            width: 16px;
            vertical-align: text-bottom;
        }

        .cp-Panel-toggle {
            font-size: inherit;

            .toggle-button {
                position: relative;
                float: left;
                margin-right: 10px;
            }

            &.cp-is-open {
                .arrow-icon {
                    transform: rotate(0deg);
                }
            }
        }

        .cp-Panel-body {
            &.cp-is-open {
                padding: 10px 0;
            }

            .editable {
                margin-left: 28px !important;
            }

            div.editable {
                padding-top: 12px;
            }
        }

        .selector-wrapper {
            margin-bottom: 20px;

            .secondary-button:first-child {
                margin-right: 10px;
            }

            .selector {
                position: relative;
                width: 95%;
            }
        }

        .company-item {
            position: relative !important;
            height: 40px;
            margin-top: 10px;
            display: inline-flex;
            align-items: center;

            img {
                margin-right: 5px;
            }

            span {
                line-height: 40px;
            }

            .remove-company {
                cursor: pointer;
                display: none;
                margin-left: 15px;
            }

            &:hover {
                .remove-company {
                    display: inline-block;
                }
            }
        }
    }
}

.working-hours {
    margin-top: 16px;

    table {
        td {
            border: none !important;

            &:first-child {
                padding-right: 20px;
            }
        }
    }

    .svg-icon {
        margin-top: 4px;
        margin-right: 9px;
    }

    md-input-container {
        height: 30px;
        white-space: nowrap;
        width: 100% !important;

        input[type="time"] {
            min-width: 85px;
        }
    }

    .secondary-button {
        margin-right: 10px;
    }
}

.editable {
    padding: 0;
    width: 85%;

    input,
    textarea {
        border: none;
        padding: 0;
    }

    &.title {
        input {
            font-weight: bold;
        }
    }

    input:focus,
    textarea:focus {
        border: solid 1px #e7e7e7;

        &:hover {
            background-color: transparent;
        }
    }

    input:hover,
    textarea:hover {
        background-color: #e7e7e7;
    }
}

.escalation-create-sidebar {
    width: 830px;
    min-width: 830px;
    max-width: 830px;
}

.escalation-details,
.escalation-create-sidebar {
    .cp-Panel-toggle {
        margin: 0;
        padding: 0;
    }

    .cp-Panel-toggle {
        font-size: inherit;
        text-transform: uppercase !important;
    }

    .targetType {
        margin-right: 20px;

        .svg-icon {
            height: 20px;
            width: 20px;
            margin-right: 5px;
            vertical-align: middle;
        }
    }

    .middle-field {
        width: 50%;
    }

    .interval-title {
        margin: 0 5px;
    }

    .select-user {
        width: 70%;
    }

    .selected-list {
        .item {
            display: inline-block;
            cursor: pointer;
            margin: 0 5px 2px 0;
            padding: 5px;

            .svg-icon {
                margin: 0 0 0 5px;
                height: 20px;
                width: 20px;
            }
        }
    }

    .section-slatarget {
        .table td {
            padding: 2px 8px;
        }
    }
}

.escalation-details .content,
.escalation-create-sidebar,
.task-details .content {
    .cp-Panel {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }

        .cp-Panel-toggle {
            background-color: #eee;
            border-radius: 10px;
            color: #5d5e64;
            display: flex;
            justify-content: left;
            padding: 4px 13px 3px;
            font-size: inherit;

            .title-icon {
                //fill: #989a9f;
                width: 20px;
                height: 20px;
                margin-right: 10px;
            }

            .arrow-icon {
                //fill: #989a9f;
                transition: .2s;
                transform: rotate(90deg);
                height: 16px;
                width: 16px;
                position: absolute;
                right: 13px;
                top: 13px;
            }

            &.cp-is-open {
                border-radius: 10px 10px 0 0;

                .arrow-icon {
                    transform: rotate(0deg);
                }
            }
        }

        .cp-Panel-body {
            border-radius: 0 0 10px 10px;

            &.cp-is-open {
                border: solid 1px #eee;
                border-top: none;
                padding: 13px;
            }
        }

        .label {
            margin-bottom: 5px;
        }
    }
}

.section-conditions,
.section-reminders,
.section-escalations {
    .condition,
    .reminder,
    .escalation {
        border: solid 1px #B0DCF7;
        border-radius: 15px 15px 10px 10px;
        background-color: #EFFAFF;

        .delete-btn {
            cursor: pointer;
        }

        table {
            width: 100%;
            padding: 10px;

            td {
                padding: 0 5px;
            }
        }

        .block-cond {
            padding: 10px 15px 5px 15px;
        }

        .block-values {
            padding: 5px 15px 15px 15px;
        }

        .separator {
            position: relative;
            margin: 10px 0;
            border: solid 1px #B0DCF7;

            &:before {
                content: "AND";
                position: absolute;
                top: -10px;
                left: 50%;
                background-color: #fff;
                border: solid 1px #B0DCF7;
                border-radius: 10px;
                height: 20px;
                padding: 0 10px;
            }

            &:nth-last-child(2) {
                display: none;
            }
        }

        .selected-list {
            padding: 2px;

            .item:hover {
                background-color: #fff;
            }
        }

        .block-footer {
            background-color: #fff;
            border-radius: 0 0 10px 10px;
            color: #3f69cc;

            span {
                display: inline-block;
                cursor: pointer;
                height: 20px;
                line-height: 20px;
                margin: 10px 15px;

                md-icon {
                    color: #3f69cc;
                }
            }
        }
    }

    .rules {
        align-items: center;
        display: flex;

        .ember-power-select-trigger {
            min-width: 185px;
        }
    }

    .title-section {
        margin: 15px 0;
    }
}

.section-slatarget {
    overflow-x: auto;

    span {
        font-weight: 600;
    }

    .urgent {
        color: #CF2A27;
    }

    .high {
        color: #FF7629;
    }

    .medium {
        color: #FCC024;
    }

    .low {
        color: #44AE5F;
    }

    .table {
        tbody tr {
            border: solid 2px #eee;
        }

        td {
            max-width: none;
            padding: 2px 15px;
        }
    }

    .select-wrap {
        .ember-power-select-trigger {
            width: 144px;
        }
    }
}

.field-creation {
    .fields {
        border: solid 1px #f5f5f5;
        padding: 10px;

        .field-switch {
            margin-bottom: 10px;
        }

        .list {
            .item {
                border: solid 2px #dadada;
                border-radius: 4px;
                margin-bottom: 5px;

                &.blur {
                    > .field-header {
                        opacity: .3;
                    }

                    .field-title {
                        cursor: default !important;
                    }

                    .expand-sections,
                    .actions {
                        display: none !important;
                    }
                }

                .item {
                    margin: 5px;
                }

                .group-subfield-list {
                    .field-header {
                        .drag-indicator {
                            display: none;
                        }
                    }
                }

                .field-header {
                    align-items: center;
                    min-height: 40px;

                    &:hover {
                        .actions {
                            display: block;
                        }
                    }

                    .field-title {
                        cursor: pointer;
                        flex: 20;
                        padding: 5px;

                        .label {
                            font-weight: bold;
                        }

                        .system,
                        .required {
                            background-color: #d4d4d4;
                            display: inline-block;
                            border-radius: 4px;
                            padding: 5px 15px;
                            margin-left: 10px;
                        }

                        .system {
                            color: #4caf50;
                        }
                    }

                    .actions {
                        display: none;

                        .svg-icon {
                            height: 22px;
                            width: 22px;
                        }
                    }
                }

                .details {
                    .choices {
                        display: flex;
                        margin-top: 10px;
                        width: 80%;

                        .drag-item {
                            display: flex;
                            margin-top: 5px;

                            &._new {
                                margin-left: 22px;
                            }
                        }

                        .drag_handle {
                            line-height: 30px;
                        }

                        svg {
                            width: 22px;
                            height: 22px;
                            vertical-align: bottom;
                        }
                    }

                    .field-form {
                        background-color: #f9f9f9;
                        padding: 5px;

                        .field-form-item {
                            display: flex;
                            margin-top: 10px;
                            width: 80%;
                        }

                        input, textarea {
                            background-color: #fff;
                            border: solid 1px #d4d4d4;
                        }
                    }

                    .edit-actions {
                        text-align: right;
                        background-color: #fff;

                        .primary-button,
                        .secondary-2-button {
                            margin: 18px 10px 18px 0;
                        }
                    }
                }

                .sections {
                    border-top: solid 2px #d4d4d4;
                    background-color: #f9f9f9;

                    .expand-sections {
                        cursor: pointer;
                        padding: 10px;
                    }

                    .section {
                        border: solid 1px #b3bec6;
                        background-color: #fff;
                        margin: 5px;

                        .empty-list {
                            padding: 10px;
                        }

                        .details {
                            .section-title {
                                padding: 10px;
                            }

                            .section-form {
                                background-color: #f9f9f9;
                                padding: 5px;

                                .section-form-item {
                                    padding: 5px;
                                    width: 70%;
                                }

                                .selected-list {
                                    margin-top: 5px;
                                    padding: 2px;

                                    .item {
                                        align-items: center;
                                        cursor: pointer;
                                        white-space: nowrap;
                                        display: inline-flex;
                                        padding: 5px;

                                        .svg-icon {
                                            margin-left: 5px;
                                        }
                                    }

                                    .item:hover {
                                        background-color: #fff;
                                    }
                                }

                                input {
                                    background-color: #fff;
                                    border: solid 1px #d4d4d4;
                                }

                                md-input-container {
                                    margin-top: 10px !important;
                                }
                            }
                        }

                        .empty-list {
                            text-align: center;
                        }

                        .section-header {
                            background-color: #ebeff3;
                            min-height: 40px;

                            &:hover {
                                .actions {
                                    display: block;
                                }
                            }

                            &.blur {
                                opacity: .3;

                                .actions {
                                    display: none !important;
                                }
                            }

                            .section-title {
                                flex: 20;
                                padding: 10px;

                                b:last-child {
                                    display: none;
                                }
                            }

                            .actions {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }

    .drag-indicator {
        display: flex;
        align-items: center;

        .svg-icon {
            width: 33px;
            height: 24px;
        }
    }

    .type-icon {
        position: relative;
        max-width: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30%;

        div {
            border-radius: 5px;
            width: 32px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;

            .svg-icon {
                width: 24px;
                height: 24px;
            }
        }
    }

    .field-types {
        background-color: #f9f9f9;

        .header {
            padding: 10px;
        }

        &.blur {
            opacity: .3;

            .item {
                cursor: default !important;
            }

            .actions {
                display: none !important;
            }
        }

        .types-list {
            .item {
                position: relative;
                background-color: #fff;
                border: solid 2px #d4d4d4;
                border-radius: 4px;
                margin: 5px;
                padding: 2px;
                cursor: pointer;

                .type-title {
                    flex: 20;
                    margin-left: 4px;

                    .label {
                        color: #606a73;
                    }

                    .description {
                        color: #d4d4d4;
                        margin: 4px 0 0;
                    }
                }

                .actions {
                    position: absolute;
                    right: 0;
                    top: 0;
                    background-color: #fff;
                    display: none;
                }

                &:hover {
                    .actions {
                        display: block;
                    }
                }
            }

        }
    }

    .-placeholderAfter {
        padding-bottom: 13px;
    }

    .-placeholderBefore {
        padding-top: 13px;
    }

    .-isEmpty {
        padding-bottom: 40px;
    }

    .-isEmpty:before,
    .-placeholderBefore:before,
    .-placeholderAfter:before {
        background-color: #a0a0a0;
        height: 3px;
        width: 100%;
        content: ' ' !important;
        margin-top: 5px !important;
        margin-bottom: 5px !important;
    }
}

.popup.max-large {
    width: 70%;
    min-width: 70%;
    max-width: 70%;
}

.merge-guest-popup {
    background: #f5f5f5;
    display: flex;
    margin-top: 20px;

    .row-merge {
        flex: 1;

        > div {
            border-bottom: 1px solid rgba(0, 0, 0, .15);
            padding: 10px;

            &:last-child {
                border: none;
            }
        }

        &:nth-child(2), &:nth-child(3) {
            > div {
                color: #1E88E5;
                cursor: pointer;
                position: relative;

                &.active {
                    &:after {
                        position: absolute;
                        right: 10px;
                        top: 5px;
                        content: "\2714";
                    }
                }
            }
        }

        &:nth-child(2) .title-avatar {
            &:after {
                position: absolute;
                content: '\279C' !important;
                top: 30px !important;
                right: 5px !important;
                font-size: 16px;
            }
        }

        &:nth-child(3) .title-avatar {
            &:after {
                position: absolute;
                content: '\22B6' !important;
                top: 30px !important;
                right: -8px !important;
                font-size: 20px;
            }
        }

        &:nth-child(4) {
            background: #fff;
            pointer-events: none;
        }
    }

    .title-avatar {
        align-items: center;
        color: #656a72 !important;
        height: 60px;

        .md-avatar {
            margin-right: 10px;
        }

        b {
            font-size: 14px;
        }

        p {
            font-size: 11px;
            margin: 3px 0;
        }
    }
}

.pell-editor-wrap {
    background: #f5f5f5;
    color: #bdbac2;
    border-radius: 6px;
    height: 300px;

    .pell-actionbar {
        border: none;
    }
}

.no-messages {
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    margin: -50px auto 0;
    display: flex;

    &._escalation, &._response {
        position: absolute;
    }

    .svg-icon {
        height: 60px;
        width: 60px;
        margin-bottom: 10px;
    }

    .title {
        color: #121416;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 10px;
    }

    .primary-button {
        background-color: #fff !important;
        border-color: #2B78E4 !important;
        color: #2B78E4 !important;
        margin-top: 12px;

        &:hover {
            background-color: rgba(158, 158, 158, 0.2) !important;
        }
    }
}

// tag-input component
.emberTagInput {
    margin: 0;
    padding: 5px 5px 2px 5px;
    border: 1px solid lightgray;
    cursor: text;
}

.emberTagInput--readOnly {
    cursor: default;
}

.emberTagInput li {
    list-style-type: none;
    display: inline-block;
}

.emberTagInput-tag,
.emberTagInput-input {
    font: 12px sans-serif;
    padding: 5px 10px;
    margin-bottom: 3px;
}

.emberTagInput-tag {
    background: cornflowerblue;
    border-radius: 20px;
    margin-right: 5px;
    color: white;
}

.emberTagInput-tag--remove {
    opacity: 0.75;
}

.emberTagInput-remove:before {
    content: 'x';
    cursor: pointer;
}

.emberTagInput-input {
    border: none;
    padding-left: 0;
}

.emberTagInput-input:focus {
    outline: none;
}

.emberTagInput-input.is-disabled {
    display: none;
}

.end-justify-align {
    display: flex;
    justify-content: flex-end;

    .secondary-2-button {
        margin-right: 15px;
    }
}

.upper-text {
    text-transform: uppercase;
}

.buttons-panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 36px;
    margin-top: 20px;

    .action-buttons {
        display: flex;

        .md-button {
            margin: 0 15px 0 0;
        }

        svg {
            width: 20px;
            height: 20px;
            vertical-align: middle;
        }
    }

    .secondary-2-button, .primary-button {
        min-width: auto;
        margin-left: 0;
        margin-right: 15px;
        pointer-events: none;

        &.active,
        &.active:hover {
            border-color: $Secondary2ButtonActiveColor;
            color: $Secondary2ButtonActiveColor;
            pointer-events: auto;

            svg {
                fill: $Secondary2ButtonActiveColor;
            }
        }

        &.active:hover {
            background-color: #effaff;
        }
    }

    .navigate-buttons {
        .clear-button {
            line-height: initial;
        }
    }

    .navigate-button {
        padding: 3px !important;
        min-height: auto !important;
        min-width: auto !important;
        width: 30px !important;
        height: 30px !important;
        margin: 0 !important;
        background: none !important;
        border: none !important;

        svg {
            width: 24px;
            height: 24px;
            fill: $TextLightGreyColor;
        }

        &.active {
            svg {
                fill: $Secondary2ButtonActiveColor;
            }
        }

        .arrow-circle-icon {
            height: 24px;
            width: 24px;

            &._right {
                transform: rotate(180deg);
            }
        }

        .arrow-icon {
            height: 16px;
            transform: rotate(270deg);
            width: 16px;

            &._right {
                transform: rotate(90deg);
            }
        }
    }
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-thumb {
    background: #9e9e9e;
    border-left: solid 4px #fff;
}

::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
}