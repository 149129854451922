.filter-contacts {
    align-items: center;
    display: flex;
    justify-content: space-between;
    height: 55px;

    .number-page {
        color: #989a9f;
        font-size: 13px;
        margin-right: 15px;
    }

    .navigate-btn {
        height: 24px;
        margin: 0 3px;
        padding: 0;
        width: 24px;

        .arrow-circle-icon {
            height: 24px;
            width: 24px;

            &._right {
                transform: rotate(180deg);
            }
        }

        .arrow-icon {
            height: 16px;
            transform: rotate(270deg);
            vertical-align: middle;
            width: 16px;

            &._right {
                transform: rotate(90deg);
            }
        }
    }

    .filter-icon {
        height: 24px;
        margin: 0 0 0 10px;
        padding: 0;
        width: 24px;
    }
}

.contacts {
    margin-top: 12px;
}

.contact-info {
    padding: 0 12px;
    height: 100vh;
    width: 30%;

    .contact-info-item {
        border-radius: 5px;
        margin-bottom: 7px;

        &:last-child {
            margin-bottom: 0;
        }

        .label {
            margin-bottom: 5px;
        }

        .paper-switch {
            margin: 0;
        }

        .info-icon {
            height: 20px;
            width: 20px;
            margin-right: 8px;
        }

        .cp-Panel-toggle {
            background: #f5f5f5;
            margin: 0;
            padding: 12px;
            align-items: center;
            display: flex;

            .arrow-icon {
                height: 16px;
                position: absolute;
                transition: .2s;
                transform: rotate(90deg);
                right: 12px;
                width: 16px;
            }

            &.cp-is-open {
                .arrow-icon {
                    transform: rotate(0deg);
                }
            }

            span {
                color: $TextGreyColor;
                font-weight: 500;
            }
        }

        .cp-Panel-body-inner {
            padding: 12px 10px;

            .svg-icon {
                margin-right: 10px;
                height: 20px;
                width: 20px;
            }

            .flatpickr-input {
                flex: 1;
                padding: 10px;
            }
        }

        .cp-Panel {
            border: solid 1px #F5F5F5;
            border-radius: 10px;
        }
    }

    .contact-avatar {
        border: 2px solid #f5f5f5;
        border-radius: 5px;
        margin-bottom: 7px;
        padding: 10px 0;
        text-align: center;
        position: relative;

        &:after {
            background-image: url('/assets/back-user.jpg');
            background-position: center;
            background-size: cover;
            content: '';
            height: 100%;
            width: 100%;
            opacity: .6;
            position: absolute;
            left: 0;
            top: 0;
        }

        .contact-info-name {
            color: #121416;
            font-size: 13px;
            font-weight: 500;
        }

        .profile_upload {
            position: relative;
            z-index: 1;

            small {
                color: #121416;
            }
        }

        .avatar-upload.uploaded {
            background: none;
        }
    }

    .md-button {
        &:hover {
            background: none;
        }
    }

    .link-email {
        border-bottom: 1px solid;
        cursor: pointer;
        display: inline-block;
        color: #007BFF;
    }

    .social-item {
        display: flex;
        margin-bottom: 10px;

        .social-icon {
            margin-right: 10px;

            > svg {
                height: 16px;
                width: 16px;
            }
        }

        .social-value {
            margin-right: 10px;
            font-size: 13px;
            font-weight: 500;
        }

        .trash-icon-btn {
            cursor: pointer;

            .trash-icon {
                height: 16px;
                width: 16px;
            }
        }
    }

    .form-social {
        .social-icon-select {
            margin-right: 10px;
            vertical-align: middle;
            height: 18px;
            width: 18px;
        }

        .action-social {
            display: flex;
            justify-content: space-between;
        }
    }

    .company-block {
        align-items: center;
        border: 1px solid grey;
        border-radius: 5px;
        padding: 5px 10px;
        display: flex;
        margin-bottom: 7px;
        position: relative;

        &:hover {
            .delete-btn {
                display: block;
            }
        }

        p {
            color: #007bff;
            margin: 2px 0;
        }

        .delete-btn {
            cursor: pointer;
            display: none;
            position: absolute;
            right: 12px;
            top: 15px;
            z-index: 1;
        }

        .type-company {
            color: grey;
            font-size: 12px;
        }

        img {
            border-radius: 100%;
            margin-right: 10px;
        }
    }

    .email-field {
        display: flex;
        margin-bottom: 10px;

        md-input-container {
            margin-right: 8px !important;
        }

        .md-button {
            min-width: 66px;
            max-height: 40px;
        }

        .primary {
            padding: 5px 10px;
            border: 1px solid rgba(0, 0, 0, .3);
            border-radius: 5px;
        }
    }
}

.contact-content {
    flex: 1;
    border: 2px solid #f5f5f5;
    border-radius: 5px;
    margin-right: 12px;

    .md-no-tab-content {
        background: #f5f5f5;
        padding: 12px 0;

        md-pagination-wrapper, md-tabs-canvas {
            height: auto;
        }

        md-pagination-wrapper {
            justify-content: space-around;
            position: relative;
            width: 38%;
        }

        .md-tab {
            font-size: 12px;
            padding: 0 0 6px;
            text-transform: uppercase;
        }
    }

    .liquid-child {
        padding: 5px 20px;
    }

    .form-create-note {
        border: 2px solid #f5f5f5;
        border-radius: 5px;
        margin-bottom: 15px;

        .form-note-title {
            border-bottom: 1px solid rgba(218, 218, 218, .5);
            display: flex;
            align-items: center;
            padding: 0 10px;

            .file-icon {
                height: 20px;
                margin-right: 8px;
                width: 20px;
            }

            .md-input {
                border: none!important;
                padding: 0;
            }
        }

        .form-create-action {
            background: #f5f5f5;
            display: flex;
            justify-content: flex-end;
            padding: 5px;

            .secondary-2-button {
                background: transparent !important;
            }

            .primary-button {
                background: #d4d3d5 !important;
                border: none;
                color: #989a9f !important;
                margin-left: 10px;
            }
        }
    }

    .wrap-editor {
        display: flex;
        flex-direction: column;

        .pell-actionbar {
            border: none;
            border-top: 1px solid rgba(218, 218, 218, .5);
            order: 2;
            padding: 5px;
        }

        .pell-content {
            border-radius: 0 !important;
            border: none !important;
            order: 1;
            position: relative;

            &:before {
                content: "Leave more details here";
                cursor: text;
                font-style: italic;
                font-size: 11px;
                position: absolute;
                left: 11px;
                top: 7px;
            }

            &:focus:before {
                content: "";
            }

            ul {
                list-style: revert;
                padding: revert;
            }

            h1 {
                font-size: 26px;
            }

            h2 {
                font-size: 22px;
            }
        }
    }

    .timeline-wrap {
        display: flex;
        margin: 0 0 0 20px;
        padding-top: 20px;
        position: relative;

        .circle {
            position: absolute;
            top: 22px;
            left: -5px;
            width: 10px;
            height: 10px;
            background: #fff;
            border-radius: 50%;
            z-index: 1;
        }

        .title {
            color: #121416;
        }

        .line {
            position: absolute;
            left: 0;
            top: 0;
            width: 1px;
            height: 100%;
            margin-left: 0;
        }

        .timeline-icon {
            margin-right: 7px;
        }

        p {
            font-size: 12px;
            margin: 5px 0;
        }
    }

    .top_action {
        display: flex;
        justify-content: flex-end;
        margin: 8px 0 15px;

        .primary-button {
            display: flex;
        }

        .plus-icon {
            height: 16px !important;
            width: 16px !important;
            margin-right: 5px;
        }
    }

    .task-list {
        border-radius: 5px;
        border: 2px solid #f5f5f5;
        margin-bottom: 12px;

        .task-title {
            background: #6f5cb9;
            border-radius: 5px 5px 0 0;
            color: #fff;
            font-size: 12px;
            font-weight: 700;
            margin: -1px;
            padding: 10px 14px;
            text-transform: uppercase;

            &._closed {
                background: #979797;
            }
        }

        .task-content {
            font-size: 11px;
            padding: 10px;

            span {
                margin-right: 10px;
            }
        }
    }

    .paper-icon {
        margin: 0 2px 2px 0;
    }

    .timeline-date {
        font-size: 11px;
        padding: 0 15px;
        margin-right: 10px;
        width: 70px;
        text-align: center;
        line-height: 1.5;
    }
}

.note-list {
    .note-item {
        border: 2px solid #f5f5f5;
        border-radius: 5px;
        color: #5d5e64;
        display: flex;
        justify-content: space-between;
        padding: 12px;
        margin-bottom: 7px;

        .user-avatar {
            margin-right: 12px;
        }

        .top_note {
            color: #5d5e64;
            font-size: 11px;
            margin-bottom: 5px;
        }

        .note-title {
            font-weight: 500;
            margin-bottom: 3px;
        }

        .updated-note {
            color: #989a9f;
            font-size: 11px;
            margin-bottom: 5px;

            b {
                color: green;
            }
        }

        .actions {
            opacity: 0;

            .md-button {
                margin: 0 3px;
                padding: 0;
            }
        }

        &:hover {
            .actions {
                opacity: 1;
            }
        }
    }
}

.filter-menu {
    padding: 10px;

    h3 {
        color: #121416;
        margin-bottom: 12px;
        font-size: 15px;
    }

    md-menu-item {
        padding: 0;

        .md-button {
            background-color: #1E20A8 !important;
            color: #fff;
            min-height: 40px;
            display: flex;
            border-radius: 5px;
            justify-content: center;

            &:hover {
                background-color: #3437d6 !important;
                color: #fff;
            }
        }
    }
}