.administration-menu {
    padding: 10px;

    .menu-title {
        color: $TitleColor;
        font-size: 18px;
        font-weight: 500;
        margin: 15px 10px;
    }

    .svg-icon {
        width: 20px;
        height: 20px;
        margin-right: 5px;

        &.marketplace-icon {
            width: 24px;
            height: 24px;
        }
    }

    .menu-link {
        font-weight: 500;
        padding: 10px;

        &:hover {
            a {
                color: $AdminMenuBlueColor;

                .svg-icon {
                    fill: $AdminMenuBlueColor;
                }
            }
        }

        &.last {
            padding-left: 0;
        }

        &.active {
            a {
                color: $AdminMenuBlueColor;
            }

            .svg-icon {
                fill: $AdminMenuBlueColor;
            }
        }

        a {
            align-items: center;
            color: #656a72;
            display: flex;
        }
    }

    .cp-Panel {
        .cp-Panel-toggle {
            align-items: center;
            display: flex;
            color: #656a72;
            font-weight: 500;
            justify-content: space-between;
            position: relative;
            padding: 10px 0;

            .arrow {
                transition: transform .3s;
            }

            &:hover {
                color: $AdminMenuBlueColor;

                .svg-icon {
                    fill: $AdminMenuBlueColor;
                }
            }

            &.cp-is-open {
                .arrow {
                    transform: rotate(90deg);
                }
            }
        }

        .cp-Panel-body-inner {
            padding: 0 0 0 15px;
        }
    }
}

.profile-block {
    display: flex;
    margin: 10px 0 0;

    .profile-wrap-avatar {
        margin: 0 90px 0 50px;

        .avatar-upload {
            border: 1px solid #ddd;
            border-radius: 10px;
            margin: auto;
            position: relative;
            width: 220px;
            height: 220px;

            &:hover {
                .edit-icon, .remove-icon {
                    display: flex;
                }
            }

            img {
                border-radius: 10px;
                object-fit: contain;
                height: 100%;
                width: 100%;
            }

            .edit-icon, .remove-icon {
                align-items: center;
                justify-content: center;
                background-color: #2b78e4;
                border-radius: 50%;
                cursor: pointer;
                display: none;
                height: 26px;
                position: absolute;
                right: 10px;
                bottom: 10px;
                width: 26px;

                > svg {
                    fill: #fff;
                    height: 15px;
                    width: 15px;
                }
            }

            .remove-icon {
                bottom: 40px;
            }
        }

        .dropzone {
            display: none;
        }
    }

    .setting-form {
        width: 400px;

        .action-buttons {
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
        }
    }
}

.settings-content {
    h3 {
        color: #121416;
        font-weight: 500;
    }

    .setting-form {
        width: 400px;

        .form-group {
            .label {
                width: 30%;
            }
        }
    }
}

.form-label {
    padding: 10px 10px 10px 0;
    display: inline-block;
    text-align: left;
    vertical-align: top;
}

.sign {
    .person, .lock, .email {
        fill: #007bff;
    }
}

.onboard-wrap {
    padding: 10px;

    .left-column {
        border: 2px solid #eee;
        border-radius: 5px;
        width: 300px;

        .column-inner {
            padding: 10px;

            h4 {
                font-weight: normal;
                margin: 18px 0 5px;
            }
        }

        .sign-up-title {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .paper-icon {
                color: green;
                margin: 0 6px;
            }
        }

        .progress-bar-info {
            background-color: #eee;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 3px 10px;
            margin: -1px;

            span {
                font-size: 14px;
                font-weight: bold;
            }

            .progress-circle {
                display: flex;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                align-items: center;
                justify-content: center;
                background-color: #ebebeb;
                position: relative;
            }

            .progress-circle:after {
                content: '';
                display: inline-block;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                -webkit-animation: colorload 2s;
                animation: colorload 2s;
            }

            .progress-circle span {
                font-size: 10px;
                color: #8b8b8b;
                position: absolute;
                width: 30px;
                height: 30px;
                line-height: 30px;
                text-align: center;
                border-radius: 50%;
                background: #fff;
            }

            .progress-circle span:after {
                content: "%";
                font-weight: 600;
                color: #8b8b8b;
            }

            .progress-circle.progress-20:after {
                background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent),
                linear-gradient(208.8deg, #66b8ff 50%, #ebebeb 50%, #ebebeb);
            }

            .progress-circle.progress-40:after {
                background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent),
                linear-gradient(259.2deg, #66b8ff 50%, #ebebeb 50%, #ebebeb);
            }

            .progress-circle.progress-60:after {
                background-image: linear-gradient(-25.2deg, #66b8ff 50%, transparent 50%, transparent),
                linear-gradient(270deg, #66b8ff 50%, #ebebeb 50%, #ebebeb);
            }

            .progress-circle.progress-80:after {
                background-image: linear-gradient(25.2deg, #66b8ff 50%, transparent 50%, transparent),
                linear-gradient(270deg, #66b8ff 50%, #ebebeb 50%, #ebebeb);
            }

            @-webkit-keyframes colorload {
                0% {
                    opacity: 0;
                    transform: rotate(0deg);
                }
                100% {
                    opacity: 1;
                    transform: rotate(360deg);
                }
            }
        }
    }

    .onboard-menu-item {
        cursor: pointer;
        display: flex;
        padding: 10px 5px;
        justify-content: space-between;

        &.disabled {
            .paper-icon:first-child, span {
                opacity: .4;
            }
        }

        .check-icon {
            color: green;
            font-weight: bold;
            margin: 0;
        }

        &.active {
            background: #007bff;
            color: #fff;

            .paper-icon {
                color: #fff;
            }
        }

        .paper-icon {
            margin-right: 7px;
        }
    }

    .onboard-profile {
        flex: 1;
        padding: 0 20px;

        h3 {
            margin-bottom: 10px;
        }

        .profile_upload {
            width: 160px;
            padding: 10px;
            text-align: center;
        }

        .form-control input {
            height: auto;
            margin: 0;
            padding: 10px 14px;
        }

        .profile-info {
            padding: 10px;

            .form-group {
                margin-bottom: 12px;
                display: flex;
                align-items: center;

                md-input-container {
                    width: 100%;
                }
            }

            .label {
                margin-bottom: 0;
                width: 120px;
            }
        }

        .language-confirm {
            margin-top: 25px;

            .ember-power-select-trigger {
                margin-top: 10px;
                width: 180px;
            }
        }

        .action-onboard {
            display: flex;
            justify-content: flex-end;
            margin-top: 10px;
        }

        .action-buttons {
            margin-top: 10px;
        }

        .mailbox-wrap {
            background: rgba(0, 0, 0, 0.05);
            border-radius: 5px;
            padding: 15px;
            margin-top: 15px;

            .mailbox-info {
                margin-bottom: 15px;
            }

            .field-box {
                margin-bottom: 15px;
                width: 380px;

                .primary-button {
                    margin-left: 10px;
                    max-height: 43px;
                    margin-top: 1px;
                }

                .svg-icon {
                    height: 24px;
                    width: 24px;
                    margin-right: 5px;
                }

                .label {
                    margin-bottom: 5px;
                }

                input {
                    background: #fff;
                    border: 2px solid grey;
                    padding: 10px;
                    border-radius: 6px;
                    height: 45px;
                    width: 100%;
                }
            }
        }

        .top-personalised {
            background: rgba(0, 0, 0, 0.05);
            border-radius: 5px;
            padding: 15px;

            .title-inner {
                margin-bottom: 10px;
            }

            .wrap-domain {
                background-color: #fff;
                border-radius: 5px;
                flex: 1;
                align-items: center;
                display: flex;
                padding: 0 10px;

                span {
                    display: inline-block;
                    font-size: 16px;
                }
            }

            .domain-input {
                align-items: center;

                input {
                    background-color: #fff;
                    font-size: 16px;
                    padding: 0;
                    border: none !important;
                    //width: 30px;
                }
            }

            .primary-button {
                border-radius: 5px;
                margin: 0 0 0 10px;
                max-height: 40px;
            }

            .form-group {
                display: flex;
                margin: 10px 0 0;
            }

            img {
                margin-right: 15px;
                width: 100px;
            }
        }

        .action-button-form {
            margin-top: 15px;
        }
    }

    .onboard-title {
        padding: 10px;
        border-bottom: 2px solid rgba(0, 0, 0, .07);
        margin-bottom: 15px;
        justify-content: flex-end;
        display: flex;
    }

    .invite-form {
        width: 90%;

        .ember-power-select-trigger {
            width: 40%;
        }

        .label {
            margin-bottom: 7px;
        }

        .paper-switch {
            margin: 10px 0;
        }

        .form-group {
            margin-bottom: 15px;
        }

        .two-field {
            display: flex;
            margin-bottom: 15px;

            > div {
                flex: 1;
            }

            .md-checkbox {
                margin: 0;
            }

            md-input-container {
                width: 80%;
            }

            .ember-power-select-trigger {
                width: 80%;
            }
        }
    }

    .cp-Panel {
        border: 1px solid #eee;
        border-radius: 5px;
        margin-bottom: 10px;
        padding: 12px 15px;

        .cp-Panel-toggle {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            font-weight: 500;

            &.cp-is-open {
                color: #2b78e4;

                .arrow-icon {
                    fill: #2b78e4;
                    transform: rotate(0deg);
                }
            }

            .arrow-icon {
                transform: rotate(90deg);
                transition: .2s;
                height: 16px;
                width: 16px;
            }
        }

        .cp-Panel-body.cp-is-open {
            margin-top: 20px;
        }
    }

    .title-instruction {
        margin-bottom: 20px;
    }

    .list-instruction li {
        margin-bottom: 15px;

        img {
            display: inline-block;
            max-width: 80%;
        }
    }
}

.progress-bar-wrap {
    cursor: pointer;
    text-align: center;
    width: 100px;

    .progress-bar-title {
        font-size: 12px;
        margin-bottom: 5px;
    }

    .progress-bar {
        height: 4px;
        background: lightgreen;

        .progress-bar-active {
            background: green;
            height: 100%;
            width: 20px;

            &.progress-40 {
                width: 40px;
            }

            &.progress-60 {
                width: 60px;
            }

            &.progress-80 {
                width: 80px;
            }
        }
    }
}

.security-block {
    padding: 10px 40px 0;
    width: 440px;

    .eye-icon, .checkmark-circle {
        height: 20px;
        width: 20px;
    }

    .form-group {
        position: relative;
    }

    .user-status-security {
        color: #34a853;
        text-transform: capitalize;
        position: relative;

        &:before {
            background-color: #34a853;
            border: 1px solid #fff;
            border-radius: 50%;
            content: '';
            display: inline-block;
            margin-right: 5px;
            vertical-align: middle;
            height: 10px;
            width: 10px;
        }

        &.away {
            color: #ffbf00;

            &:before {
                background-color: #ffbf00;
            }
        }

        &.offline {
            color: #cacaca;

            &:before {
                background-color: #cacaca;
            }
        }
    }

    .full-name {
        color: #231f20;
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 7px;
    }

    .alert-icon {
        height: 24px;
        width: 24px;
        position: absolute;
        right: -40px;
        top: 28px;
        fill: #d62516;
    }

    .title-avatar {
        border: 1px solid #ddd;
        border-radius: 10px;
        height: 115px;
        width: 115px;
        margin: 0 20px 35px 0;

        img {
            border-radius: 10px;
            object-fit: contain;
            height: 100%;
            width: 100%;
        }
    }

    .svg-icon-wrap {
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 10px;
    }

    .action-buttons {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
    }

    md-input-container {
        width: 100%;
    }

    .password-field {
        display: flex;
        justify-content: space-between;
    }

    .validation-container {
        .svg-icon {
            margin-right: 7px;
        }

        .item-validate {
            margin-bottom: 12px;
            display: flex;
            align-items: center;

            &.valid {
                color: #54a702;

                .svg-icon {
                    fill: #54a702;
                }
            }
        }
    }
}

.profile_upload {
    .avatar-upload {
        background: #dadada;
        border-radius: 100%;
        display: flex;
        margin: 0 auto 15px;
        width: 58px;
        height: 58px;
        cursor: pointer;
        position: relative;

        .person-icon {
            height: 34px;
            margin: auto;
            width: 34px;
        }

        .plus-icon {
            height: 24px;
            position: absolute;
            right: -10px;
            bottom: -2px;
            width: 24px;
        }

        img {
            border-radius: 100%;
            max-width: 100%;
            margin: 0 auto;
            object-fit: cover;
        }
    }

    .dropzone {
        display: none;
    }

    small {
        color: #adadad;
        font-size: 12px;
        font-weight: 400;
    }

    .remove-avatar {
        cursor: pointer;
        display: inline-block;
        margin-bottom: 10px;
        font-size: 12px;
    }
}

.user-profile {
    border-right: 1px solid #dadada;
    width: 240px;

    .profile-title {
        color: #231f20;
        font-weight: 500;
        font-size: 16px;
        padding: 15px 20px 20px;;
    }

    .profile-menu {
        padding: 0 6px;
        margin-top: 10px;

        a {
            display: flex;
            background-color: #effaff;
            border-radius: 5px;
            padding: 6px 14px;
        }
    }

    .icon-bell {
        height: 20px;
        width: 20px;
        margin-right: 8px;
    }

    .label {
        color: #2B78E4;
        font-weight: 500;
        font-size: 14px;
    }
}

.notification-form {
    padding: 20px;

    md-radio-button {
        display: inline-block;
        margin-right: 25px;
    }

    .notification-form-group {
        margin-bottom: 15px;
    }

    .notification-warn {
        border-radius: 4px;
        background-color: rgba(224, 30, 90, .1);
        border: 1px solid rgba(224, 30, 90, .4);
        padding: 12px 16px;
        display: flex;

        .svg-icon {
            fill: #e01e5a;
            margin-right: 5px;
            height: 20px;
            width: 20px;
        }
    }

    .paper-switch, .md-checkbox {
        margin: 0;
    }

    .notification-form_icon {
        height: 24px;
        width: 24px;
        margin-right: 10px;
    }

    .notification-form_title {
        margin-left: 34px;

        .label {
            color: #5d5e64;
            font-weight: 500;
            margin-right: 10px;
        }

        .snooze-title {
            margin: 5px 10px 10px 0;
        }

        .label-field {
            margin-bottom: 5px;
        }

        .flatpickr-input {
            background-color: transparent;
            padding: 9px 10px;
            position: relative;
            width: 100%;
            z-index: 1;
        }

        .date-wrap-field {
            width: 245px;
            margin-bottom: 12px;
            position: relative;

            .calendar-icon {
                height: 16px;
                width: 16px;
                position: absolute;
                top: 29px;
                right: 10px;
                z-index: 0;
            }
        }

        .checkbox-field {
            margin-top: 12px;

            .md-label {
                margin-top: 3px;
            }
        }

        .ember-power-select-trigger {
            width: 245px;
        }
    }
}