.attachments {
    display: flex;
    flex-wrap: wrap;

    .attachment-icon, .trash-icon {
        height: 20px !important;
        width: 20px !important;
        fill: #6198ff;
    }

    .trash-icon {
        margin-left: 3px;
    }

    .md-button {
        min-width: auto;
        padding: 0;
        line-height: initial;
    }

    .file {
        align-items: center;
        display: flex;
        width: 150px;
        background-color: #eee;
        margin: 0 5px 5px 0;
        padding: 5px;

        a {
            align-items: center;
            display: flex;
            margin-right: 5px;
        }
    }

    img {
        height: 35px;
        width: fit-content;
    }
}

.column-select {
    padding: 16px;

    h4 {
        margin-bottom: 10px;
        text-transform: uppercase;
    }

    p {
        line-height: 20px;
    }

    .drag-list {
        margin-top: 30px;
        line-height: 24px;

        svg {
            width: 16px;
            height: 16px;
            vertical-align: text-bottom;
            margin-right: 7px;
            cursor: pointer;

            &.green {
                fill: #54A702;
                opacity: 0.5;
            }
        }

        .dragSortItem {
            display: flex;
            align-items: center;

            span {
                flex: 10;
            }

            md-icon {
                opacity: 0.5;
            }
        }
    }
}

.organizations-table, .contacts-table {
    .md-body {
        td:nth-child(2) {
            min-width: 150px;
        }
    }
}

.tasks-list {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;

    table td {
        .ember-power-select-trigger {
            min-width: 115px;
        }
    }

    .assignedTo {
        display: inline-block;
        padding: 5px;
        cursor: pointer;
        white-space: nowrap;

        &:hover {
            background-color: white;
        }
    }

    .due-date {
        white-space: nowrap;

        &.expired {
            color: #E24B4B;
        }
    }

    .watcher-task-panel {
        .selected-list {
            padding: 2px;
            margin-bottom: 10px;

            .item {
                display: inline-block;
                cursor: pointer;
                white-space: nowrap;
                margin-bottom: 5px;
                padding: 5px;

                md-icon,
                img {
                    vertical-align: middle;
                }
            }

            .item:hover {
                background-color: #fff;
            }
        }
    }

    .scrollable-content {
        height: 100%;
    }

    .grid-place {
        display: flex;
        height: 100%;
        min-height: 0;

        .side-panel {
            background-color: #f7f7f7;
            box-shadow: 0 0 4px 2px rgb(0 0 0 / 8%);
            display: flex;
            flex-direction: column;
            margin: 5px;
            min-width: 30%;
            max-width: 30%;
            width: 30%;

            &.hidden {
                display: none;
            }

            .panel-header {
                text-transform: uppercase;
                padding: 10px 0 10px 10px;
                color: $TitleColor;
                font-weight: 500;
                letter-spacing: 1px;
                border-bottom: 2px solid #eee;
            }

            .panel-slug {
                padding: 10px;
            }

            .panel-form {
                flex: 20;
                display: flex;
                flex-direction: column;
                min-height: 0;
                height: 100%;
                padding: 10px;

                .form-content {
                    flex: 20;
                    overflow: auto;
                    padding: 0 0 10px;

                    .label {
                        .svg-icon {
                            margin-right: 5px;
                            height: 20px;
                            width: 20px;
                        }
                    }

                    .item {
                        display: inline-flex;

                        .md-avatar {
                            margin-right: 5px;
                        }

                        .svg-icon {
                            margin-left: 5px;
                            height: 20px;
                            width: 20px;
                        }
                    }

                    input, textarea, .form-class, .pell-content {
                        background: #fff;
                    }

                    .flatpickr-input {
                        min-height: 40px;
                        flex: 1;
                    }

                    .icon-date {
                        height: 24px;
                        width: 24px;
                        margin-right: 8px;
                    }
                }

                .form-footer {
                    display: flex;
                    justify-content: space-between;

                    ._clear-btn {
                        margin-right: 15px;
                    }
                }
            }
        }

        .empty-tasks-list {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            margin-top: 25%;
            color: #888;

            .icon {
                font-size: 40px;
            }

            p {
                text-align: center;
                font-size: 18px;
                margin: 10px 0 0;
                line-height: 32px;
            }

            .openBtn {
                cursor: pointer;

                &:hover {
                    border-bottom: 1px solid;
                }
            }
        }
    }
}

.merge-task-panel {
    .search-block {
        position: relative;

        .search-icon {
            position: absolute;
            width: 16px;
            height: 16px;
            z-index: 1;
            top: 13px;
            left: 7px;
        }

        input {
            padding-left: 25px;
        }
    }

    .merge-list {
        .item {
            background-color: #fff;
            border-radius: 10px;
            margin: 0 0 10px 0;
            padding: 10px;
            display: flex;
            flex-direction: row;
            align-items: center;

            button {
                cursor: default;
            }

            .action,
            .option {
                padding: 10px;

                svg {
                    width: 24px;
                    height: 24px;
                }
            }

            .option {
                width: 60px;
                font-size: 10px;
                line-height: 12px;

                svg {
                    display: block;
                    margin: 0 auto;
                }
            }

            &.secondary {
                .action,
                .option {
                    button {
                        cursor: pointer;
                    }
                }
            }

            &.primary {
                .action {
                    svg {
                        opacity: .5;
                    }
                }

                .title {
                    color: #3f69cc;
                }
            }

            .title {
                margin-bottom: 5px;

                .slug {
                    color: #3f69cc;
                }
            }

            .description {
                padding: 10px;
                flex: 10;
            }

            .info {
                font-size: 80%;

                svg {
                    display: none;
                }
            }

            .option {
                text-align: center;
            }
        }
    }
}

.create-contact-field-popup,
.task-form,
.panel-form {
    margin: 0;
    padding: 0;

    .form-row {
        margin-right: -7.5px;
        margin-left: -7.5px;
        align-items: center;
        display: flex;
        flex-wrap: wrap;
    }

    .form-group {
        margin-bottom: 16px;
        flex-grow: 1;
        position: relative;

        &.last {
            margin-bottom: 0;
        }

        label {
            position: relative;
            color: #656a72;
            font-size: 16px;
            font-weight: 600;
            line-height: 1.7;
            margin-bottom: 2px;
            display: inline-block;

            svg {
                width: 20px;
                height: 20px;
                vertical-align: middle;
            }
        }

        .flatpickr-input {
            min-height: 40px;
            flex: 1;
        }

        .icon-date {
            height: 24px;
            width: 24px;
            margin-right: 8px;
        }
    }

    .col-sm-6 {
        box-sizing: border-box;
        padding-right: 7.5px;
        padding-left: 7.5px;
        flex: 0 0 50%;
        max-width: 50%;
        position: relative;
        width: 100%;
    }
}

.popup.create-popup {
    .guest-group {
        display: flex;
        flex-direction: row;

        .guest-fields {
            width: 100%;
            min-width: 50%;
        }

        .list-guest {
            width: 50%;
            min-width: 50%;
            max-height: 400px;
            overflow-y: auto;
            padding: 5px;
        }
    }
}

.task-board-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    overflow-x: auto;

    .task-board-column {
        display: flex;
        flex-direction: column;
        min-width: 300px;
        width: 24%;
        min-height: 97%;
        max-height: 97%;
        margin-right: 10px;

        .board-column-header {
            display: flex;
            line-height: 25px;
            padding: 10px;
            background-color: #f7f7f7;
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            font-weight: 500;
            color: #121416;

            span {
                display: inline-block;
                border-radius: 50%;
                width: 25px;
                height: 25px;
                text-align: center;
                color: #fff;
                margin-left: 20px;
                background-color: grey;
            }

            span.OPEN {
                background-color: $colorTaskStatusOpen;
            }

            span.IN_PROGRESS {
                background-color: $colorTaskStatusInProgress;
            }

            span.RESOLVED {
                background-color: $colorTaskStatusResolved;
            }

            span.CLOSED {
                background-color: $colorTaskStatusClosed;
            }
        }

        .board-column-content {
            overflow-y: auto;
            padding: 0 10px;
            background-color: #f7f7f7;

            .board-card {
                display: flex;
                margin-bottom: 10px;
                padding: 2px;
                border-radius: 5px;
                border: 2px solid transparent;

                &.selected {
                    border-color: #3f69cc;
                }

                .card-priority {
                    width: 6px;
                    background-color: $colorTaskPriorityLow;
                }

                .card-priority.URGENT {
                    background-color: $colorTaskPriorityUrgent;
                }

                .card-priority.HIGH {
                    background-color: $colorTaskPriorityHigh;
                }

                .card-priority.MEDIUM {
                    background-color: $colorTaskPriorityMedium;
                }

                .card-content {
                    border-radius: 0 5px 5px 0;
                    position: relative;
                    background-color: #fff;
                    padding: 5px;
                    flex: 1;
                    border: solid 1px transparent;

                    .task-id {
                        margin-bottom: 4px;

                        .link {
                            color: #121416 !important;
                            font-weight: 500;
                        }
                    }

                    .task-status, .task-del-btn {
                        position: absolute;
                        top: 2px;
                        right: 35px;

                        .svg-icon {
                            width: 22px;
                            height: 22px;
                        }
                    }

                    .task-del-btn {
                        cursor: pointer;
                        display: none;
                        right: 5px;
                    }

                    &:hover {
                        .task-del-btn {
                            display: block;
                        }
                    }

                    .task-title {
                        margin-bottom: 4px;

                        .svg-icon {
                            height: 20px;
                            width: 20px;
                        }
                    }

                    .task-assignee {
                        display: inline-flex;
                        align-items: center;
                        margin-bottom: 4px;
                        cursor: pointer;

                        &:hover {
                            background-color: #f7f7f7;
                        }

                        .md-avatar {
                            margin-right: 5px;
                        }

                        .svg-icon {
                            height: 20px;
                            width: 20px;
                            transform: rotate(90deg);
                            margin-left: 5px;
                        }
                    }

                    .task-department {
                        margin-bottom: 7px;
                    }

                    .task-watchers {
                        align-items: center;
                        display: flex;

                        > div {
                            margin-right: 5px;
                        }

                        .add-btn {
                            cursor: pointer;
                        }
                    }

                    .card-footer {
                        align-items: center;
                        display: flex;
                        flex-wrap: wrap;
                        margin-top: 12px;

                        .svg-icon {
                            margin-right: 2px;
                            width: 22px;
                            height: 22px;
                        }

                        .alignCenter {
                            margin-right: 7px;
                        }

                        .due-date {
                            align-items: center;
                            display: flex;

                            &.expired {
                                color: #E24B4B;

                                .svg-icon {
                                    fill: #E24B4B;
                                    vertical-align: middle;
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                }
            }

            .-placeholderAfter {
                padding-bottom: 13px;
            }

            .-placeholderBefore {
                padding-top: 13px;
            }

            .-isEmpty {
                padding-bottom: 40px;
            }

            .-isEmpty:before,
            .-placeholderBefore:before,
            .-placeholderAfter:before {
                background-color: #a0a0a0;
                height: 3px;
                width: 100%;
                content: ' ' !important;
                margin-top: 5px !important;
                margin-bottom: 5px !important;
            }
        }

        .primary-button {
            margin: 10px 0 0;
            width: 100%;
            font-size: 14px;
        }
    }
}

.task-details {
    .task-info {
        margin-bottom: 10px;
    }

    .description,
    .comment {
        margin: 10px 0;
    }

    .task-info,
    .description,
    .comment {
        padding: 10px;
        background-color: #fff;
        border: solid 1px #F5F5F5;
        border-radius: 10px;

        div {
            margin: 5px;
        }

        .source {
            text-transform: capitalize;
        }
    }

    .comment {
        color: $TextGreyColor;

        &.note {
            border-color: #FFDBA6;
            background-color: #FFF5E7;
        }
    }

    .comment:last-child {
        margin-bottom: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .column {
        max-height: 100%;
        overflow-y: auto;

        &:not(:last-child) {
            margin-right: 15px;
        }
    }

    .reply-panel {
        padding: 10px;
        background-color: #eee;

        .md-button.active {
            border-bottom: solid 2px #3f69cc;
        }

        .reply-editor,
        .note-editor {
            display: none;
            margin-top: 10px;

            &.visible {
                display: block;
            }

            .option {
                padding: 5px;
            }

            .pell-content {
                background-color: #fff;
                margin-bottom: 5px;
            }

            .buttons {
                text-align: right;
                margin-top: 10px;

                .primary-button {
                    margin-right: 10px;
                }
            }
        }
    }

    .cp-Panel-body-inner {
        md-content {
            background-color: #fff !important;
        }
    }

    .form-group {
        color: #989a9f;

        .svg-icon {
            margin-right: 5px;
            height: 20px;
            width: 20px;
        }

        .flatpickr-input {
            height: 40px;
            flex: 1;
        }

        .file {
            float: none;
            width: auto;
            height: auto;
        }

        .value {
            margin-left: 30px;

            .link {
                color: #989a9f !important;
            }
        }
    }

    .contact-details {
        .name,
        .form-group {
            margin-bottom: 20px;

            .md-avatar {
                border-radius: 50%;
                vertical-align: middle;
            }

            svg {
                width: 20px;
                height: 20px;
                margin-right: 10px;
                vertical-align: text-bottom;
            }
        }
    }

    .watchers-list {
        .watcher {
            line-height: 40px;
            margin-bottom: 5px;

        }

        .ember-power-select-trigger {
            flex: 1;
            margin-right: 10px;
        }

        img,
        md-icon {
            vertical-align: middle;
        }
    }

    .user-avatar {
        vertical-align: middle;
    }

    .file-upload-drag-drop {
        margin: 8px 0 0 30px;
    }

    .action-buttons {
        display: flex;
        justify-content: space-between;

        .primary-button {
            margin-left: 10px;
        }
    }
}

.activities-timeline {
    position: relative;
    width: 100%;
    margin: 0 0 10px 10px;
    padding: 0;
    list-style-type: none;

    .link_mentioned {
        display: none;
    }

    li {
        padding: 1em 0;
        display: flex;
        align-items: center;

        &:hover {
            .action-btn {
                display: flex;
            }
        }

        &:before {
            position: absolute;
            left: 0;
            top: 0;
            content: ' ';
            display: block;
            width: 2px;
            height: 100%;
            margin-left: 0;
            background: #f3f6f8;
            z-index: 5;
        }

        &:after {
            content: "";
            display: block;
            height: 0;
            clear: both;
            visibility: hidden;
        }

        .action-btn {
            margin-left: 20px;
            display: none;

            .md-button {
                margin: 0;
                padding: 0;
                min-width: 50px;
            }
        }

        .direction {
            position: relative;
            text-align: left;

            .flag-wrapper {
                position: relative;
                display: inline-block;
                text-align: center;
            }

            .flag {
                color: #121416;
                position: relative;
                display: inline;
                padding: 6px 20px;
                border-radius: 5px;
                font-weight: 600;
                text-align: left;

                &:before {
                    position: absolute;
                    top: 50%;
                    left: -6px;
                    content: ' ';
                    display: block;
                    width: 10px;
                    height: 10px;
                    margin-top: -5px;
                    background: #fff;
                    border-radius: 10px;
                    border: 2px solid #3f69cc !important;
                    z-index: 10;
                }
            }

            .desc {
                padding-left: 20px;
                margin-top: 5px;
                font-size: 14px;
            }
        }

        &:first-child {
            padding: 0;
        }

        &:last-child {
            padding: 0;
        }
    }

    &.guest-activity {
        .flag {
            font-weight: 400 !important;
        }

        .flag.note {
            &:before {
                border: 2px solid #5c54ff !important;
            }
        }

        .flag.inbox {
            &:before {
                border: 2px solid #3f69cc !important;
            }
        }

        .flag.booking {
            &:before {
                border: 2px solid #b26e00 !important;
            }
        }

        .flag.task {
            &:before {
                border: 2px solid #017872 !important;
            }
        }

        .flag:before {
            top: 33px !important;
        }
    }
}
