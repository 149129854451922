.kb-menu {
    display: flex;
    flex-direction: column;
    width: 300px;

    .menu-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $TitleColor;
        font-size: 15px;
        font-weight: 500;
        padding: 15px 10px 15px 20px;
        cursor: pointer;

        .md-button {
            width: 26px;
            height: 26px;
            min-width: auto;

            &:hover {
                background-color: transparent;
            }
        }

        .svg-icon {
            border-radius: 3px;
            height: 17px;
            width: 17px;
        }
    }

    .sections-list .item {
        font-weight: 500;
        justify-content: left;

        .alignCenter {
            flex: 1;
        }
    }
}

.sections-list {
    height: 100%;
    max-width: 100%;
    padding: 10px;

    &.bases-list {
        flex: 100;
        max-height: 100%;

        &.short {
            flex: 100;
            max-height: 40%;
        }

        &.single {
            flex: 1;
            max-height: unset;
        }
    }

    &.short {
        flex: 200;
        max-height: 100%;

        .item {
            &:hover {
                font-weight: 500;
            }

            .indicator {
                margin: 0 10px 0 0;
            }

            .title {
                max-width: unset;
            }

            .actions {
                min-width: 44px;
            }
        }
    }

    .item {
        width: 100%;
        display: flex;
        align-items: center;
        min-height: 48px;
        border: 2px solid #eeecec;
        border-radius: 3px;
        transition: .3s;
        margin-bottom: 8px;
        padding: 2px 5px 2px 2px;
        cursor: pointer;
        justify-content: space-between;
        position: relative;

        &.hilighted {
            background-color: #e7e7e7;
        }

        &.selected, &:hover {
            border-color: #2b78e4;
        }

        &:hover {
            .actions {
                visibility: visible;
            }
        }

        .alignCenter {
            display: flex;
            flex: 1;
            margin-right: 10px;
            overflow: hidden;
        }

        .status {
            margin: 0 5px;
        }
    }

    .title {
        color: $TitleColor;
        font-weight: 500;

        & + div {
            color: $TitleColor;
        }
    }

    .indicator {
        height: 39px;
        width: 4px;

        &.PUBLISHED {
            background-color: green;
        }

        &.DRAFT {
            background-color: orange;
        }
    }

    .drag-indicator {
        display: flex;
        align-items: center;
        height: 100%;

        &.system {
            .svg-icon {
                visibility: hidden;
            }
        }

        .svg-icon {
            width: 33px;
            height: 24px;
        }
    }

    .title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
        z-index: 1;

        > div:first-child {
            color: $TitleColor;
            font-weight: 500;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .info {
            font-weight: normal;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    .author {
        display: flex;
        align-items: center;
        white-space: nowrap;
        //min-width: 150px;
        min-width: 10em;

        img {
            margin-right: 5px;
        }
    }

    .last_updated {
        width: 180px;
    }

    .type, .views {
        white-space: nowrap;

        .svg-icon {
            margin-right: 3px;
        }
    }

    .actions {
        visibility: hidden;
        display: flex;
        z-index: 1;
        //min-width: 180px;
        min-width: 11em;
        justify-content: right;

        .md-button {
            margin: 0;
        }

        .svg-icon {
            height: 22px;
            width: 22px;
        }
    }

    .ratings {
        display: flex;

        .svg-icon {
            margin-right: 6px;
            height: 16px;
            width: 16px;
            fill: #219653 !important;
        }

        .like, .dislike, .rating {
            width: 30px;
        }

        .dislike-icon {
            fill: #e24b4b !important;
            transform: rotateX(180deg);
        }

        .rating {
            color: #219653;
        }
    }
}

.kb-settings {
    &.widget-settings {
        margin-top: 0;
    }

    .form-group {
        .paper-switch {
            display: flex;
            align-items: center;

            .md-container {
                margin-right: 10px;
            }
        }
    }

    .md-checkbox {
        margin-bottom: 10px;
    }
}

.kb-inner-wrapper {
    .page-title {
        flex-direction: row;

        .md-icon-button {
            line-height: 42px;
            height: 28px;
            width: 28px;
        }
    }

    .svg-icon {
        &.icon-sections {
            fill: green;
        }

        &.icon-categories {
            fill: blue;
        }

        &.icon-articles {
            fill: #caca16;
        }

        &.icon-magic-styles {
            fill: orange;
        }

        &.icon-settings {
            fill: red;
        }

        &.icon-insights {
            fill: purple;
        }
    }

    .buttons-panel {
        padding-right: 20px;
    }

    .wrap-switch {
        display: flex;
        align-items: center;

        span {
            margin-right: 5px;
            color: grey;
            display: inline-block;

            &.public {
                color: green;
            }
        }
    }

    .page-inner-content {
        padding: 0;

        .sections-list {
            padding: 0;
        }

        form {
            .secondary-2-button {
                margin-right: 10px;
            }

            .error {
                color: red;
                margin: 15px 0 10px;
            }
        }

        h2 {
            color: $TitleColor;
        }
    }

    .page-footer {
        margin-top: 20px;
    }

    .kb-landing {
        text-align: center;
        max-width: 900px;
        margin: 0 auto;

        h2 {
            margin-bottom: 10px;
        }

        .kb-tabs {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-top: 20px;

            .kb-tab {
                padding: 15px;
                border-radius: 3px;
                box-shadow: 0 2px 4px 0 rgb(0 0 0 / 3%), 0 4px 22px 0 rgb(0 0 0 / 9%);
                //border: 1px solid rgba(51, 51, 51, .1);
                transition: all .2s ease-in-out;
                margin: 10px 15px 15px 10px;
                cursor: pointer;
                flex-basis: 30%;
                display: flex;
                align-items: center;
                flex-direction: column;

                &:hover {
                    transform: scale(1.02);
                    background-color: #f9f9f9;
                    box-shadow: 0 2px 7px 0 rgb(0 0 0 / 7%), 0 7px 29px 0 rgb(0 0 0 / 10%);
                }

                .svg-icon {
                    &.icon-sections {
                        height: 30px;
                        width: 30px;
                    }

                    &.icon-articles {
                        height: 34px;
                        width: 34px;
                    }

                    &.icon-settings {
                        height: 32px;
                        width: 32px;
                    }
                }

                .kb-icon {
                    height: 40px;
                    width: 40px;
                    align-items: center;
                    display: flex;
                    justify-content: center;
                }

                .svg-icon {
                    width: 100%;
                    height: 100%;
                }

                .header {
                    color: $TitleColor;
                    font-weight: 500;
                    margin: 10px 0;
                }

                .info {
                    font-size: 14px;
                }
            }
        }
    }

    .grid-content {
        flex: 1;
        display: flex;
        flex-direction: column;

        .ql-container {
            border: none;
        }

        .ql-tooltip {
            left: auto !important;
            right: 50%;
            transform: translate(50%, -50%);
        }
    }

    .status {
        display: inline-block;
        width: 65px;
        //margin: 0 5px;

        &.PUBLISHED {
            color: green;
        }

        &.DRAFT {
            color: orange;
        }
    }

    .ql-toolbar {
        border: 1px solid #eeecec;
        border-radius: 0 0 5px 5px;

        &.focus {
            border-color: #b1b1b1;
        }
    }

    .ql-toolbar {
        border-radius: 5px 5px 0 0;
        background-color: #f3f3f3;
    }

    .section-editor {
        display: flex;
        flex-direction: row;
        min-height: 100%;
        height: 100%;

        &.magic-style {
            .section-details {
                margin-top: 0;
            }
        }

        .error {
            .md-input,
            .ember-power-select-trigger {
                border-color: red !important;
            }

            &.grid-content {
                .ember-scrollable {
                    border: solid 2px red !important;
                }
            }
        }

        .section-body {
            display: flex;
            flex-direction: column;
            flex: 1;

            .footer {
                margin: 15px 0 0;
                display: flex;
                justify-content: space-between;
            }

            .wrap-arrows {
                margin: 10px 10px 0;

                .svg-icon {
                    transform: rotate(90deg);
                    height: 24px;
                    width: 24px;
                }
            }

            .banner {
                display: flex;
                flex-direction: row;
                padding: 10px;
                border-radius: 5px;
                background-color: $AdminMenuHoverColor;
                align-items: center;

                .text {
                    flex: 10;
                }

                .button {
                    padding-left: 10px;
                    display: flex;
                }
            }

            .picker-popup-wrapper {
                right: 10px;
            }
        }

        .section-details {
            width: 300px;
            margin: 25px 0 0 10px;

            .avatar-upload {
                margin: 0;
            }

            .remove-avatar {
                margin-bottom: 0;
            }

            .dz-preview {
                display: block;

                .dz-details {
                    display: none;
                }
            }

            .info-item {
                background: #f9f9f9;
                border-radius: 2px;
                border: 1px solid #dadada;
                box-shadow: 0 0 2px 1px rgb(0 0 0 / 8%);
                padding: 5px 10px;
                margin-bottom: 8px;

                .arrow-icon {
                    transition: .2s;
                    transform: rotate(90deg);
                    height: 16px;
                    width: 16px;
                }

                .cp-Panel-toggle {
                    display: flex;
                    justify-content: space-between;
                    font-weight: 500;
                    padding: 10px 0;
                    color: $TitleColor;

                    &.cp-is-open {
                        .arrow-icon {
                            transform: rotate(0deg);
                        }
                    }
                }

                .cp-is-disabled {
                    .cp-Panel-toggle {
                        color: #dadada;
                    }
                }

                .avatar-upload,
                .avatar-upload-logo,
                .avatar-upload-bg {
                    width: 50px;
                    height: 50px;

                    img {
                        width: 50px;
                        height: 50px;
                    }
                }

                .dropzone {
                    width: 0;
                    min-width: 0;
                    height: 0;
                    min-height: 0;
                    overflow: hidden;
                    border: none;
                    padding: 0;
                }

                .theme-color-field {
                    align-content: center;
                    justify-content: space-between;
                    background: #fff;
                    border: 1px solid #eeecec;
                    border-radius: 5px;
                    display: flex;
                    padding: 5px;
                    margin-bottom: 15px;
                    width: 180px;
                    position: relative;
                }

                .pickr {
                    display: inline-block;
                    position: absolute;
                    left: 0;
                    top: 0;
                    opacity: 0;
                    width: 100%;
                    height: 100%;

                    .pcr-button {
                        position: absolute;
                        opacity: 0;
                        width: 100%;
                        height: 100%;
                    }
                }

                .droplet-icon {
                    margin-right: 3px;
                    height: 18px;
                    width: 18px;
                    vertical-align: bottom;
                }

                .color-palette-icon {
                    fill: #dadada;
                    height: 20px;
                    width: 20px;
                }
            }
        }
    }
}

.preview-article {
    min-height: 80%;
    width: 100%;

    &.md-default-theme {
        margin-left: 85px;
    }

    .scrollable-content {
        height: 100%;
    }

    .md-dialog-content {
        background-color: #f7f9fa;
        display: flex;
        flex: 1;
        padding: 0;
    }

    .wrap-row {
        display: flex;
        flex-wrap: wrap;
    }

    .logo-section {
        margin: 0 auto 10px;
        height: 80px;
        width: 80px;

        img, .svg-icon {
            border-radius: 100%;
            max-height: 100%;
            max-width: 100%;
        }

        svg {
            opacity: .2;
            margin-top: 10px;
            height: 60px;
            width: 60px;
        }
    }

    .kb-item {
        border-radius: 3px;
        box-shadow: rgb(0 0 0 / 3%) 0 0 0 1px, rgb(241 243 245) 0 2px 10px 0, rgb(0 0 0 / 5%) 0 3px 3px 0;
        cursor: pointer;
        font-size: 14px;
        min-height: 180px;
        text-align: center;
        margin: 0 30px 30px 0;
        padding: 45px 20px 25px;
        width: calc(100% / 3 - 20px);

        &:nth-child(3n) {
            margin-right: 0;
        }

        .svg-icon {
            height: 50px;
            width: 50px;
            margin-bottom: 10px;
        }

        .title {
            color: $TitleColor;
            margin-bottom: 15px;
            font-size: 18px;
            font-weight: 500;
        }

        .description {
            margin-bottom: 15px;
        }

        .count {
            color: #2B78E4;
        }
    }
}

.kb-preview {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;

    .preview-header {
        display: block;
        max-height: none;
        height: auto;
        padding: 30px 70px;
        background-color: $MainMenuBlueColor;
        color: white;

        .logo {
            width: 100px;
            height: 100px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;

            &.small {
                width: 50px;
                height: 50px;
            }

            &.large {
                width: 150px;
                height: 150px;
            }
        }
    }

    .search-input {
        margin-top: 10px;
        position: relative;

        .popup-search-articles {
            background-color: #fff;
            border-radius: 0 0 5px 5px;
            box-shadow: rgb(0 0 0 / 5%) 0 0 0 1px, rgb(0 0 0 / 5%) 0 3px 3px 0;
            position: absolute;
            left: 0;
            top: 100%;
            padding: 10px;
            min-height: 60px;
            max-height: 165px;
            overflow: auto;
            width: 250px;
            z-index: 1;

            > div {
                color: #121416;
                cursor: pointer;
                margin-bottom: 10px;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .preview-content {
        background-color: #f7f9fa;
        padding: 30px 70px;

        .bread-crumbs {
            margin-bottom: 10px;

            span {
                cursor: pointer;
                color: red;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .section-block {
            margin-bottom: 15px;

            &.tag-articles {
                .article {
                    border-radius: 3px;
                    border: 1px solid #eee;
                    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
                    cursor: pointer;
                    display: block;
                    font-size: 16px;
                    min-height: 40px;
                    margin-bottom: 12px;
                    padding: 15px;
                    width: 50%;
                }

                .title-article {
                    margin-bottom: 5px;
                }

                .published-category {
                    color: #989a9f;
                    font-size: 12px;
                    margin-top: 8px;

                    span {
                        color: #2B78E4;
                    }
                }
            }

            .section-title {
                color: $TitleColor;
                font-size: 24px;
                font-weight: 500;
                margin-bottom: 12px;
                cursor: pointer;
            }

            .search-title {
                color: $TitleColor;
                font-size: 18px;
                margin-bottom: 12px;
            }
        }

        .divider {
            margin-bottom: 20px;

            &:after {
                content: '';
                background-color: #2B78E4;
                height: 4px;
                display: inline-block;
                width: 40px;
            }
        }

        .category-aside {
            width: 250px;

            h2 {
                color: $TitleColor;
                margin-bottom: 20px;
            }
        }

        .content-aside {
            background-color: #fff;
            box-shadow: rgb(0 0 0 / 5%) 0 0 0 1px, rgb(241 243 245) 0 5px 20px 0, rgb(0 0 0 / 5%) 0 3px 3px 0;
            border-radius: 5px;
            flex: 1;
            height: 100%;
            padding: 20px;

            .ql-container {
                border: none;
            }
        }

        .categories, .articles {
            > div {
                color: $TitleColor;
                font-size: 15px;
                margin-bottom: 10px;

                &:hover > span {
                    text-decoration: underline;
                }

                &.active {
                    font-weight: 700;
                }

                span {
                    cursor: pointer;
                    display: inline-block;
                }
            }

            .article:hover > span {
                text-decoration: underline;
            }
        }

        .no-articles {
            color: #121416;
            text-align: center;
        }

        .articles {
            .logo-section {
                margin: 0 0 10px;
            }
        }

        .categories {
            .svg-icon {
                height: 10px;
                width: 10px;
                fill: $TitleColor;
                margin-left: 5px;
                vertical-align: middle;
                transform: rotate(90deg);
            }

            .sub-articles {
                margin: 4px 0 0 10px;
                font-weight: 400;

                .divider {
                    margin-bottom: 10px;
                }

                .article {
                    margin-bottom: 5px;

                    &.active {
                        font-weight: 700;
                    }
                }
            }
        }
    }

    .search-input {
        margin-top: 10px;
    }

    .title {
        font-size: 24px;
        font-weight: 500;
        color: $TitleColor;
        margin-bottom: 15px;
    }

    .ql-editor {
        padding: 0;
    }

    .rating-wrap, .tags-wrap {
        border: 2px solid #dadada;
        border-radius: 5px;
        color: #121416;
        text-align: center;
        padding: 10px;
        margin-top: 15px;

        a {
            display: inline-block;
            margin-left: 2px;
        }

        .title-rating {
            font-size: 16px;
            color: $TitleColor;
            font-weight: 500;
            margin-bottom: 10px;
        }

        .icons-rating {
            margin-bottom: 10px;

            .md-button {
                padding: 0;
                height: 38px;
                width: 38px;
                line-height: 1;
                margin: 0 5px;
            }

            .svg-icon {
                height: 26px;
                width: 26px;
                fill: #219653;

                &.dislike {
                    fill: #e24b4b;
                    transform: rotateX(180deg);
                }
            }
        }
    }

    .tags-wrap {
        text-align: left;
    }
}

#toolbar-container .ql-font {
    span[data-label="Roboto"]::before {
        font-family: 'Roboto', sans-serif;
    }

    span[data-label="Arial"]::before {
        font-family: 'Arial', sans-serif;
    }

    span[data-label="Lato"]::before {
        font-family: 'Lato', sans-serif;
    }
}

.ql-font-roboto {
    font-family: 'Roboto', sans-serif;
}

.ql-font-arial {
    font-family: 'Arial', sans-serif;
}

.ql-font-lato {
    font-family: 'Lato', sans-serif;
}

@media (min-width: 1600px) {
    .kb-menu {
        width: 400px;
    }
}
