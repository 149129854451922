.paper-switch {
    margin: 0;

    .md-bar {
        width: 38px;
        height: 24px;
        border-radius: 12px;
        top: 0;
        left: 0;
    }

    &.md-checked:not([disabled]) {
        .md-bar {
            background-color: $Primary2ButtonColor;
        }

        &.md-focused {
            .md-thumb::before {
                background-color: transparent;
            }
        }
    }

    .md-ink-ripple,
    .md-thumb::before {
        color: transparent !important;
        background-color: transparent !important;
    }

    .md-thumb {
        left: 1px;
        background-color: $InputBackgroundColor !important;
    }

    .md-thumb-container {
        z-index: 0;
    }
}

input[type="text"],
input[type="password"],
input[type="number"],
input[type="email"],
input[type="phone"],
input[type="search"],
textarea,
.pell-content {
    background-color: $InputBackgroundColor;
    border: solid 2px $InputBorderColor !important;
    border-radius: 5px !important;
    box-sizing: border-box;
    font-size: 13px;
    padding: 5px 10px;

    &:focus {
        border-color: #2B78E4 !important;
    }
}

.ember-power-select-group-name {
    display: block;
}

.ember-power-select-trigger {
    border-color: $InputBorderColor !important;
    border-radius: 5px !important;
    border-width: 2px !important;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 44px !important;
    padding: 7px 10px !important;

    &:focus, &.ember-power-select-trigger--active {
        border-color: $InputBorderColor !important;
    }

    &:after {
        display: none !important;
    }

    .ember-power-select-placeholder {
        margin: 0;
    }

    input {
        border: none !important;
        padding: 0 !important;
    }

    img {
        margin-right: 7px;
    }

    .ember-power-select-status-icon {
        margin: 0 0 0 10px;
        position: relative;
        right: 0;
    }

    .ember-power-select-selected-item {
        align-items: center;
        display: flex;
        margin: 0;
    }

    .ember-power-select-multiple-options {
        display: flex;
        flex-wrap: wrap;

        .ember-power-select-multiple-option {
            align-items: center;
            background: #ddebff;
            border: none;
            color: #2b78e4;
            display: flex;
            float: none;
            padding: 4px 10px 4px 2px;

            .ember-power-select-multiple-remove-btn {
                text-align: center;
                width: 25px;
            }
        }
    }
}

.ember-power-select-dropdown {
    border-color: $InputBorderColor !important;

    .ember-power-select-options {
        max-height: 300px !important;
    }

    .social-icon-select {
        margin-right: 10px;
        vertical-align: middle;
        height: 18px;
        width: 18px;
    }

    .ember-power-select-option {
        padding: 5px;

        &[aria-disabled=true] {
            opacity: .4;
        }

        img {
            margin-right: 7px;
            vertical-align: middle;
        }

        .bold {
            font-weight: 700;
        }

        .status {
            color: green;
            text-transform: capitalize;

            &.internal {
                color: #2b78e4;
            }

            &.draft {
                color: orange;
            }
        }
    }
}

md-input-container {
    padding: 0;
    margin: 0 !important;
    width: 100%;

    .md-errors-spacer {
        min-height: 0;
    }

    &.md-input-focused, .md-input-invalid {
        .md-input {
            padding: 10px;
        }
    }

    .md-input {
        color: #989a9f;
        min-height: 40px;
        padding: 10px;
        width: 100%;

        &.ng-invalid.ng-dirty {
            padding: 10px;
        }
    }
}

.form-group {
    margin-bottom: 16px;
    position: relative;

    &.disabled {
        pointer-events: none;

        .copy-btn {
            background-color: #dadada;
            color: #adadad;
        }
    }

    .sub-form-group {
        margin-bottom: 8px;
    }

    label {
        color: #121416 !important;
        font-weight: 400 !important;
    }

    &.in-row {
        display: flex;
        flex-direction: row;

        label {
            flex: 1;
            line-height: 34px !important;
        }
    }

    .md-label,
    label,
    .label,
    span {
        .svg-icon {
            width: 20px;
            height: 20px;
            vertical-align: middle;
        }
    }

    .label {
        color: $TitleColor;
        font-weight: 500;
        margin-bottom: 5px;
    }

    .title-icon {
        height: 20px;
        width: 20px;
        margin-right: 5px;
    }

    .extra-text {
        font-size: 12px;
        margin-top: 4px;
    }

    .info-icon {
        width: 24px !important;
        height: 24px !important;
        margin-right: 12px;
    }
}

.form-group {
    md-radio-group {
        display: flex;
        padding: 10px;

        md-radio-button {
            margin: 0 20px 0 0;
        }
    }
}

md-sidenav {
    md-toolbar {
        background-color: $InputBackgroundColor !important;
        text-transform: uppercase;
        color: #121416 !important;

        button {
            //display: none !important;
        }
    }
}

.md-sidenav-right {
    .md-toolbar-tools {
        padding: 34px 45px 10px 45px;
    }
}

md-dialog {
    md-toolbar {
        background-color: $Primary2ButtonColor !important;

        button {
            display: none !important;
        }
    }

    .md-dialog-content {
        color: $TextLightGreyColor;

        .content-title {
            color: $TitleColor;
            font-weight: 500;
            margin-bottom: 14px;
        }

        .ember-power-select-trigger {
            margin-bottom: 10px;
        }

        p, b {
            display: inline-flex;
            line-height: 20px;
            margin-bottom: 12px;
        }

        b {
            color: $TextLightGreyColor;
            font-weight: 400;
        }

        svg {
            width: 20px;
            height: 20px;
            margin-right: 8px;
        }
    }
}

.time-interval-select {
    display: flex;
    align-items: center;

    .ember-basic-dropdown-trigger {
        padding: 0 !important;
        justify-content: center;
        width: 30px;

        .ember-power-select-status-icon {
            display: none;
        }
    }

    span {
        margin: 0 8px 0 4px;
    }
}

md-menu-content {
    padding: 0;

    md-menu-item {
        min-height: 40px;
        height: 40px;
        padding: 5px 10px;

        .svg-icon {
            width: 20px;
            height: 20px;
            margin-right: 5px;
        }

        a, .md-button {
            box-shadow: none;
            font-size: 14px;
            color: $TitleColor;
            display: flex;
            align-items: center;
            margin: 0;
            padding: 0;
            letter-spacing: 0;
            min-width: 0;
            justify-content: initial;

            &:hover {
                background-color: transparent !important;
                color: #2b78e4;

                .svg-icon {
                    fill: #2b78e4;
                }
            }
        }

        .md-ripple-container {
            transition: none;
        }
    }
}

table {
    border-spacing: 0;

    &.md-table {
        .md-cell, .md-column {
            width: auto !important;
            max-width: unset !important;
            padding: 0 12px;

            &.checkbox,
            &.actions {
                width: 2em !important;
            }
        }
    }

    thead {
        th {
            text-transform: uppercase;
            color: $TextLightGreyColor;
            font-size: 12px;
            border-bottom: solid 1px #F5F5F5;
        }
    }

    tbody {
        tr {
            td {
                //border-bottom: solid 1px #F5F5F5;

                &:first-child {
                    border-left: solid 1px #F5F5F5;
                }

                &:last-child {
                    border-right: solid 1px #F5F5F5;
                }

                .paper-switch {
                    margin: 0;
                }
            }
        }
    }
}

table.md-table {
    thead {
        th {
            font-size: 14px !important;
        }
    }

    tbody {
        tr {
            td {
                font-size: 14px !important;

                .md-button {
                    font-size: 14px !important;
                }
            }
        }
    }
}

.admin-table {
    &.loading {
        opacity: .2;
    }

    &._fixed {
        table {
            table-layout: fixed;

            .md-table th.md-column:last-child {
                padding-right: 40px;
            }

            td {
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    table {
        thead {
            th {
                text-transform: none !important;
                color: $TitleColor !important;
                font-size: 14px !important;
            }
        }

        tbody {
            tr {
                &:nth-child(odd) {
                    background-color: #eee;
                }

                td {
                    color: #989a9f !important;
                    min-width: 40px;

                    .link {
                        color: $TitleColor !important;
                        white-space: nowrap;
                        font-weight: 500;
                    }

                    .title {
                        color: $TitleColor !important;
                    }

                    .svg-icon {
                        fill: #989a9f !important;
                    }

                    .md-avatar {
                        margin-right: 5px;
                        vertical-align: middle;
                    }
                }
            }
        }
    }
}

svg {
    display: inline-block;
    fill: $LabelColor;
    min-width: 0;
    min-height: 0;
}

.user-selector,
.department-selector,
.status-selector,
.priority-selector,
.id-selector,
.source-selector {
    margin: 0;

    md-checkbox {
        float: left;
        clear: both;
    }
}

.priority-selector,
.source-selector,
span.NA,
span.PHONE,
span.EMAIL,
span.LIVECHAT,
span.WECHAT,
span.TELEGRAM,
span.LINE,
span.TWITTER,
span.WHATSAPP,
span.FACEBOOK,
span.INSTAGRAM,
span.URGENT,
span.HIGH,
span.MEDIUM,
span.LOW {
    svg {
        width: 16px;
        height: 16px;
        vertical-align: text-bottom;
    }
}

.source-selector.NA,
span.NA {
    svg {
        display: none;
    }
}


$colorTaskPriorityUrgent: #CF2A27;
$colorTaskPriorityHigh: #FF6F1D;
$colorTaskPriorityMedium: #FBBC05;
$colorTaskPriorityLow: #34A853;

span.URGENT {
    color: $colorTaskPriorityUrgent;

    svg {
        fill: $colorTaskPriorityUrgent;
        vertical-align: text-bottom;
    }
}

span.HIGH {
    color: $colorTaskPriorityHigh;

    svg {
        fill: $colorTaskPriorityHigh;
        vertical-align: text-bottom;
    }
}

span.MEDIUM {
    color: $colorTaskPriorityMedium;

    svg {
        fill: $colorTaskPriorityMedium;
        vertical-align: text-bottom;
    }
}

span.LOW {
    color: $colorTaskPriorityLow;

    svg {
        fill: $colorTaskPriorityLow;
        vertical-align: text-bottom;
    }
}

$colorTaskStatusOpen: #6163C2;
$colorTaskStatusInProgress: #FF9900;
$colorTaskStatusResolved: #53AE34;
$colorTaskStatusClosed: #68758F;
$colorTaskStatusReopened: #F6B26B;
$colorTaskStatusArchived: #a5a5a5;
$colorTaskStatusDeleted: #a5a5a5;

span.OPEN {
    color: $colorTaskStatusOpen;
}

span.IN_PROGRESS {
    color: $colorTaskStatusInProgress;
}

span.RESOLVED {
    color: $colorTaskStatusResolved;
}

span.CLOSED {
    color: $colorTaskStatusClosed;
}

span.RE_OPENED {
    color: $colorTaskStatusReopened;
}

span.ARCHIVED {
    color: $colorTaskStatusArchived;
}

span.DELETED {
    color: $colorTaskStatusDeleted;
}

.flatpickr-calendar {
    .flatpickr-current-month .flatpickr-monthDropdown-months {
        -webkit-appearance: none !important;
    }
}