.flow-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;

    md-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin: 0 !important;
    }

    .main-block {
        border-bottom: 1px solid rgba(218, 218, 218, .5);
        display: flex;
        flex: 1;
        overflow: auto;
        position: relative;

        .active-flow {
            > * {
                //pointer-events: none;
            }
        }
    }

    .title-flow {
        background: #f5f5f5;
        border-bottom: 1px solid rgba(218, 218, 218, .5);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;

        .title-activate {
            position: relative;
        }

        .paper-switch {
            margin: 0;
            width: 50px;
        }

        .status-save {
            position: absolute;
            font-weight: 500;
            left: 0;
            top: 18px;
            transition: .1s;
        }

        label {
            margin-right: 7px;
            vertical-align: middle;
        }

        md-input-container {
            width: auto;

            &.md-input-invalid .md-input {
                border-color: #d50000 !important;
            }
        }
    }

    .fields-data {
        position: relative;

        .single-option {
            background: #fff;
            font-size: 14px;
            margin-top: 20px;
            padding: 10px;
            text-align: center;
            min-height: 40px;

            &::after {
                background: #aaa;
                content: "";
                height: 40px;
                margin-left: -1px;
                position: absolute;
                left: 50%;
                top: 100%;
                width: 2px;
            }
        }

        .add-component-flow {
            margin-top: 9px;
        }
    }

    .tabs-flow {
        border-left: 1px solid rgba(218, 218, 218, .5);
        display: flex;
        flex-direction: column;
        overflow: auto;
        padding: 0 10px;
        width: 510px;

        .liquid-container {
            flex: 1;
            overflow: scroll;
        }

        .form-group {
            .label {
                margin-bottom: 8px;

                .svg-icon {
                    margin-right: 5px;
                    height: 20px;
                    width: 20px;
                }
            }
        }

        .item-field-data {
            padding: 10px;
            border: 1px solid rgba(218, 218, 218, .5);
            border-radius: 5px;
            justify-content: space-between;
            margin-bottom: 10px;

            .label {
                display: block;
                margin-bottom: 3px;
            }

            .trash-icon {
                position: relative;
                right: auto;
                top: auto;

                > svg {
                    height: 18px;
                    width: 18px;
                }
            }
        }

        .item-field-data-icon {
            margin-right: 10px;

            > svg {
                height: 20px;
                width: 20px;
            }
        }

        .item-field {
            margin-bottom: 16px;

            &.single {
                .trash-icon {
                    position: relative;
                    margin-left: 10px;
                    top: auto;
                    right: auto;
                }

                .alignCenter {
                    margin-bottom: 10px;
                }
            }

            &.period {
                md-input-container, .ember-power-select-trigger {
                    flex: 1;
                }

                .md-input {
                    min-height: 44px;
                }

                .ember-power-select-trigger {
                    margin-left: 10px;
                }
            }

            .secondary-2-button {
                width: 100%;
            }

            .md-checkbox {
                margin: 0;
            }

            .time-select {
                margin-left: 10px;
            }

            .form-control-unit {
                display: inline-block;
                margin-right: 7px !important;
                width: 20%;
            }

            .svg-icon {
                width: 16px;
                height: 16px;
                vertical-align: text-bottom;
            }

            .subj-input {
                position: relative;

                .md-input {
                    padding-right: 40px;
                }

                .md-menu {
                    position: absolute;
                    top: 12px;
                    right: 10px;
                    font-weight: bold;

                    &:hover {
                        color: $MainMenuBlueColor;
                        cursor: pointer;
                    }
                }
            }

            &.branch-panel {
                border: solid 1px #F7F6FB;
                border-radius: 10px;

                .branch-header {
                    background-color: #F7F6FB;
                    padding: 0 10px;

                    .md-input-container {
                        width: 90%;
                    }

                    .svg-icon {
                        width: 24px;
                        height: 24px;
                        display: inline-block;
                        vertical-align: middle;
                    }

                    .md-focused {
                        .md-input {
                            padding: 5px 10px !important;
                        }
                    }

                    input {
                        border: none !important;
                        display: inline-block;
                        background-color: transparent;
                    }
                }

                .branch-body {
                    padding: 10px;
                }
            }

            &.trigger-panel {
                border: solid 1px #F7F6FB;
                border-radius: 10px;

                .trigger-header {
                    background-color: #F7F6FB;
                    padding: 5px 10px;

                    .svg-icon {
                        width: 20px;
                        height: 20px;
                        display: inline-block;
                        vertical-align: middle;
                    }
                }

                .trigger-body {
                    padding: 10px;

                    .rule-table {
                        tr td {
                            &:first-child {
                                min-width: 120px;
                                max-width: 160px;
                            }

                            .operator {
                                display: inline-block;
                                width: 40px;
                            }
                        }
                    }

                    .period-selector {
                        align-items: center;
                        display: flex;

                        .ember-power-select-trigger {
                            margin: 0 2px;
                        }

                        .md-input {
                            padding: 10px 2px;
                            text-align: center;
                            min-height: 44px;
                            width: 32px;
                        }
                    }
                }
            }

            .rule-table {
                margin-top: 5px;
                width: 100%;
                max-width: 100%;

                td {
                    padding: 3px 3px 0 0;
                    text-align: center;

                    &:last-child {
                        padding-right: 0;
                    }
                }

                .md-button {
                    margin-left: 0;
                    margin-right: 0;
                }

                .min {
                    width: 0;
                }
            }

            .pell-header {
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                border: solid 1px $InputBorderColor;
                padding: 10px;
                background-color: #f5f5f5;
                color: $TextBlackColor;
            }

            .pell-content {
                border-top: none !important;
                border-bottom: none !important;
                border-left: solid 1px $InputBorderColor;
                border-right: solid 1px $InputBorderColor;
            }

            .email-body-editor {
                color: $TextBlackColor;
                display: flex;
                flex-direction: column;

                .pell-actionbar {
                    order: 2;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                    border: solid 1px $InputBorderColor;
                }
            }

            .selected-tags {
                margin-bottom: 5px;
            }
        }

        .group-name {
            font-weight: bold;
            margin: 20px 0 10px;
        }

        h4 {
            font-weight: 400;
            margin-bottom: 14px;
        }

        .item-component {
            border: 2px solid #f5f5f5;
            border-radius: 5px;
            cursor: pointer;
            margin-bottom: 10px;

            &:hover {
                border-color: #e0e0e0;
                color: $TextBlackColor;
            }

            &:last-child {
                .icon-component {
                    background: orange;
                }
            }

            &.disabled {
                pointer-events: none;
                opacity: .3;
            }

            .icon-component {
                background: #4caf50;
                border-radius: 5px;
                display: flex;
                align-items: center;
                margin: -2px 10px -2px -2px;
                padding: 10px;

                .svg-icon {
                    fill: #fff;
                    height: 20px;
                    width: 20px;
                }

                &.blue {
                    background-color: #A0C5F9;
                }

                &.green {
                    background-color: #B8D7A8;
                }

                &.purple {
                    background-color: #8E7CC4;
                }

                &.orange {
                    background-color: #FDBC06;
                }

                &.red {
                    background-color: #E16666;
                }
            }
        }

        .md-no-tab-content {
            padding: 12px 0 5px;
        }

        md-pagination-wrapper {
            justify-content: space-between;
            height: auto;
            width: 100%;
        }

        .md-tab {
            font-size: 12px;
            padding: 0 0 6px;
            text-transform: uppercase;
        }
    }

    //  C A N V A S ->
    .canvas-block {
        cursor: grab;
        overflow: auto;
        flex: 1;
        user-select: none;
        position: relative;
        padding: 10px 10px 60px;
        width: fit-content;

        &.loading {
            overflow: hidden;
        }

        .application-loading-wrapper {
            background: rgba(0, 0, 0, .35);
            top: 0;
            left: 0;
            margin: auto;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .loader > div {
                background-color: #fff;
                height: 12px;
                width: 12px;
            }
        }
    }

    .justifyStart {
        display: flex;
        justify-content: flex-start;
    }

    .wrap-list-actions {
        .flexDisplay {
            align-content: center;
            white-space: nowrap;
            display: flex;
            flex-direction: row;
            margin-bottom: 10px;
        }

        .paper-switch {
            margin: 0;
        }

        .emberTagInput-input {
            background-color: #f5f5f5;
            width: 100%;
        }

        .action-delete {
            display: flex;
            align-content: center;
            justify-content: center;
            width: 35px;

            .trash-icon {
                margin: auto;
                right: auto;
                top: auto;
                position: relative;
            }
        }

        .controls-btn {
            flex-grow: 1;

            button {
                margin: 5px 0 0;
            }
        }

        .emberTagInput {
            margin: 0;
            background-color: #f5f5f5;
            white-space: normal;
            border-radius: 5px;
        }

        .action-body {
            width: 50%;
        }

        .action-label {
            margin-right: 10px;
            display: flex;
            align-items: center;
            width: 40%;
        }
    }

    .single-options {
        margin: auto;
        position: relative;
        width: fit-content;
        white-space: nowrap;

        &::after {
            border-bottom: 2px solid #aaa;
            content: "";
            position: absolute;
            left: 0;
            top: 20px;
            width: 100%;
        }

        .wrap-single-option {
            position: relative;

            &::after {
                background: #aaa;
                content: "";
                height: 20px;
                margin-left: -1px;
                margin-top: 2px;
                position: absolute;
                left: 50%;
                top: 20px;
                width: 2px;
            }

            &.active {
                &::after {
                    background-color: #2B78E4;
                }
            }

            &:first-child::before {
                background: #fff;
                content: "";
                height: 2px;
                margin-left: -1px;
                position: absolute;
                left: 0;
                top: 20px;
                width: 50%;
                z-index: 1;
            }

            &:last-child::before {
                background: #fff;
                content: "";
                height: 2px;
                margin-right: -1px;
                position: absolute;
                right: 0;
                top: 20px;
                width: 50%;
                z-index: 1;
            }

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .item-flow-input {
        height: 100%;
        width: 100%;

        textarea {
            border: none !important;
            min-height: 70px;
        }

        input {
            border: none !important;
            min-height: 35px;
            padding: 5px 10px !important;
            background-color: transparent;
            text-align: center;
        }
    }

    .wrap-single-option {
        margin: 0 30px;
        display: inline-block;
        vertical-align: top;
        width: auto;
    }

    .single-option {
        background: #fff;
        border: 2px solid #aaa;
        border-radius: 5px;
        cursor: pointer;
        margin: 40px auto 0;
        position: relative;
        width: 180px;
        z-index: 1;

        &.branch {
            .item-flow-input {
                padding-right: 0;
            }

            &.active0 {
                background-color: #E2F4E9 !important;
                border-color: #2B78E4 !important;

                input {
                    color: #24A42C;
                }
            }

            &.active1 {
                background-color: #FCD0D1 !important;
                border-color: #2B78E4 !important;

                input {
                    color: #E16666;
                }
            }
        }

        &::after {
            background: #aaa;
            content: "";
            height: 40px;
            margin: 2px 0 0 -1px;
            position: absolute;
            left: 50%;
            top: 100%;
            width: 2px;
        }

        &.invalid {
            border-color: red;
        }

        &.action {
            margin: 20px auto;
            padding: 10px;
            width: 200px;

            &::after {
                height: 0;
            }
        }

        > .add-component-flow {
            margin-top: 47px;
        }
    }

    .add-component-flow {
        background-color: #fff;
        cursor: pointer;
        margin: 11px auto 0;
        left: 0;
        right: 0;
        position: absolute;
        height: 22px;
        width: 22px;
        z-index: 1;

        &.active .svg-icon {
            fill: #2B78E4;
        }

        .svg-icon {
            height: 22px;
            width: 22px;
        }
    }

    .group-card {
        background: #f5f5f5;
        border-radius: 5px;
        margin-bottom: 7px;
        padding: 7px;
        position: relative;

        h5 {
            text-transform: uppercase;
        }

        .md-input {
            background: #fff;
        }

        .attachment-field {
            position: relative;
        }

        .icon-file-icon {
            background: $Primary2ButtonColor;
            border-radius: 5px;
            color: #fff;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 38px;
            width: 100px;
            margin: 1px;
            position: absolute;
            right: 0;
            top: 0;

            > svg {
                height: 18px;
                width: 18px;
                margin-right: 6px;
                fill: #fff;
            }
        }
    }

    .add-card, .add-option {
        margin-bottom: 10px;
    }

    .trash-icon {
        cursor: pointer;
        right: 5px;
        top: 5px;
        position: absolute;
        z-index: 1;

        > svg {
            height: 18px;
            width: 18px;
        }
    }

    .item-flow-wrap {
        margin: 0 auto;

        .flow-icon {
            //background: orange !important;
        }

        .add-component-flow {
            margin-top: 11px;
        }
    }

    .item-flow-wrap, .item-flow, .item-flow-bottom {
        background: #fff;
        border-radius: 5px;
        border: 2px solid #aaa;
        cursor: pointer;
        position: relative;
        width: 200px;

        &::after {
            background: #aaa;
            content: "";
            height: 40px;
            margin: 2px 0 0 -1px;
            position: absolute;
            left: 50%;
            top: 100%;
            width: 2px;
        }

        &.branches {
            &::after {
                height: 22px;
            }
        }

        &.active-branch {
            &::after {
                background-color: #2B78E4;
            }
        }

        &.active {
            border-color: $Primary2ButtonColor !important;
        }

        .item-flow-title {
            color: #1b1e21;
            padding: 12px 10px;

            .item-flow-input {
                padding-right: 20px;
            }
        }

        .item-flow-info {
            align-items: center;
            background: #f5f5f5;
            border-radius: 0 0 5px 5px;
            display: flex;
            height: 38px;
            position: relative;
            padding-right: 28px;
            white-space: normal;

            .count-options, .count-cards {
                background: #2B78E4;
                border-radius: 50%;
                color: #fff;
                display: inline-block;
                right: 5px;
                font-weight: 600;
                line-height: 20px;
                position: absolute;
                height: 20px;
                text-align: center;
                width: 20px;
            }
        }

        .flow-icon {
            //background: #4caf50;
            border-radius: 0 0 0 4px;
            position: absolute;
            left: -2px;
            bottom: -2px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: calc(100% + 2px);
            width: 35px;

            & + span {
                display: inline-block;
                margin-left: 45px;
            }

            .svg-icon {
                height: 20px;
                width: 20px;
                //fill: #fff;
            }

            /*&.blue {
                background-color: #A0C5F9;
            }

            &.green {
                background-color: #B8D7A8;
            }

            &.purple {
                background-color: #8E7CC4;
            }

            &.orange {
                background-color: #FDBC06;
            }

            &.red {
                background-color: #E16666;
            }*/
        }

        &.play .flow-icon {
            //background: orange;
        }
    }

    .item-flow-wrap, .item-flow {
        &.options-single, &.play, &.slider, &.hide-add {
            &::after {
                height: 22px;
            }

            .add-component-flow {
                display: none;
            }
        }
    }

    /*.item-flow, .item-flow-bottom, .single-option {
        z-index: 0;

        &.active {
            z-index: 1;

            &.message > .add-component-flow {
                z-index: 1;
            }

            & + .fields-data {
                z-index: 1;
            }

            &.options-single + .single-options > .wrap-single-option > .single-option {
                z-index: 1;
            }

            &.slider + .item-flow-bottom {
                z-index: 1;
            }
        }
    }*/

    .item-flow {
        margin: 40px auto 0;

        &.invalid {
            border-color: red;
        }

        .item-flow-title {
            padding: 0;
        }

        &.single {
            &::after {
                background: #f5f5f5;
                content: "";
                height: 40px;
                margin-left: -1px;
                margin-top: 2px;
                position: absolute;
                left: 50%;
                top: 100%;
                width: 2px;
            }

            .add-component-flow {
                display: none;
            }
        }

        &.terminator {
            &::after {
                display: none;
            }
        }

        .trash-icon {
            display: none;
        }

        &:hover {
            .trash-icon {
                display: block;
            }
        }
    }

    .item-flow-bottom {
        background: #f5f5f5;
        border: none;
        margin: 20px auto 0;
        min-height: 350px;
        text-align: center;

        &.slider {
            .card-item {
                background: #fff;
                border-radius: 5px;
                border: 2px solid #aaa;
                width: 200px !important;

                .title {
                    .md-input {
                        font-weight: 600;
                    }
                }
            }

            .slick-slide {
                width: auto !important;
            }

            .card-image {
                border-radius: 5px 5px 0 0;
                height: 180px;
                border-bottom: 1px solid #aaa;
                background-size: cover;
                background-position: center;

                &.no-image {
                    background: #b2b2b2;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;

                    &::after {
                        content: 'Add image';
                        color: #fff;
                        font-weight: 600;
                        margin-top: 5px;
                    }

                    &::before {
                        content: '';
                        background: url("/assets/images/camera.png") no-repeat center;
                        background-size: cover;
                        height: 30px;
                        width: 30px;
                    }
                }
            }

            .card-action {
                border-radius: 0 0 5px 5px;
                border-top: 1px solid #aaa;
                color: #2B78E4;
                font-weight: 600;
                padding: 10px;
            }

            .titles {
                flex: 1;
                padding: 0 10px;

                .md-input {
                    border: none !important;
                    padding: 0 !important;
                }
            }
        }
    }

    .footer-actions {
        padding: 10px 20px;
    }
}

.rulesWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .rule-item {
        flex: 1;
        margin-right: 10px;
        min-width: 27%;

        input::placeholder {
            font-size: 15px;
        }
    }

    &:last-child {
        .operator {
            display: none;
        }
    }

    .trash-icon {
        position: relative;
        right: auto;
        top: auto;
    }
}

//   C H A T  -  B O T  ->
.chat-container {
    background: #fff;
    height: 450px;
    margin: 10px auto;
    width: 300px;
    display: flex;
    flex-direction: column;
    z-index: 10;
    border-radius: 5px;
    box-shadow: 1px -1px 10px 2px rgba(0, 0, 0, 0.15);
    transition: all 250ms ease-in-out;

    &.hidden {
        opacity: 0;
        transform: scale3d(0, 0, 0);
    }

    .close-chat {
        cursor: pointer;
        height: 25px;
        width: 25px;

        &:hover svg {
            fill-opacity: 1;
        }

        svg {
            fill: #fff;
            fill-opacity: .65;
            transition: all .2s ease-in;
        }
    }

    .header {
        background-color: #007bff;
        border-radius: 5px 5px 0 0;
        color: #fff;
        padding: 14px 12px;
    }

    .input-field {
        align-content: center;
        border-top: 1px solid #e9e8e8;
        display: flex;

        .textarea {
            width: 100%;
        }

        textarea {
            border: none !important;
            border-radius: 0 !important;
            padding: 6px 50px 6px 10px;
            transition-duration: 500ms;
            transition-property: border-top;
        }

        .send-button {
            display: flex;
            align-items: center;
            cursor: pointer;
            justify-content: center;
            width: 60px;

            > svg {
                height: 13px;
                width: 15px;
            }
        }
    }

    .body {
        padding-top: 50px;
        position: relative;
        flex: 1;
        background-color: #fff;
        overflow: hidden;

        .list-wrapper {
            position: absolute;
            padding: 10px;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            flex-direction: column;
            overflow-x: hidden;
            overflow-y: scroll;

            .message {
                margin: 10px 0;

                .body {
                    background-color: #eee;
                    border-radius: 4px;
                    display: inline-block;
                    max-width: 70%;
                    font-size: 14px;
                    padding: 10px 10px 12px;
                }

                .message-buttons {
                    text-align: center;
                    margin: 15px auto 0;

                    .msg-btn {
                        background: none;
                        outline: none;
                        cursor: pointer;
                        font-weight: 600;
                        border-radius: 20px;
                        text-transform: none;
                        color: #007bff;
                        border: 1px solid #007bff;
                        padding: 8px 14px;
                        margin: 5px;
                        font-size: 14px;
                        white-space: nowrap;
                        display: inline-block;
                        box-shadow: 0 0 4px rgba(0, 0, 0, .06);

                        &:hover {
                            background: #007bff;
                            color: #fff;
                        }
                    }
                }

                .icon {
                    min-height: 28px;
                    min-width: 28px;
                    background-color: red;
                    border-radius: 50%;
                    display: inline-block;
                    margin-left: 5px;
                    margin-right: 10px;
                    float: left;
                }

                &.right {
                    text-align: right;

                    .body {
                        background-color: #007bff;
                        color: #fff;
                        text-align: left;
                    }
                }
            }
        }
    }
}

.slick-list {
    background-color: #fff;
}

.slick-prev {
    left: 4px;
    top: 72px;
    z-index: 10;

    &:before {
        font-size: 35px;
        color: #cebfbf;
    }
}

.slick-next {
    right: 20px;
    top: 72px;
    z-index: 10;

    &:before {
        font-size: 35px;
        color: #cebfbf;
    }
}

.message-slider {
    border-radius: 4px;
    text-align: center;
    width: 85%;
    box-sizing: border-box;
    background: #eee;
    padding: 10px;
    margin: 15px auto 0;

    .message-button {
        p {
            text-align: left;
            padding: 12px;
            margin: 0;
            font-size: 14px;
        }

        .block-image {
            background-size: cover;
            background-position: 50% 50%;
            background-repeat: no-repeat;
            height: 150px;
            display: block;
        }
    }

    .button-img {
        border: none;
        border-top: 1px solid rgba(0, 0, 0, .08);
        cursor: pointer;
        color: #2B78E4;
        padding: 12px 12px 14px;
        display: inline-block;
        box-sizing: border-box;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: .5px;
        width: 100%;
        outline: none;
        background: none;

        &:hover {
            background: #fbfbfb;
        }
    }
}

.chat-widget {
    background-color: #007bff;
    right: 20px;
    bottom: 20px;
    width: 60px;
    height: 60px;
    cursor: pointer;
    border-radius: 50%;
    position: fixed;
    box-shadow: -1px 2px 10px rgba(0, 0, 0, 0.28);
    display: inline-block;
    transition: all 250ms ease-in-out;

    &.hidden {
        opacity: 0;
        transform: scale3d(0, 0, 0);
    }

    &:before {
        color: #fff;
        content: "Test this Recipe";
        position: absolute;
        width: 120px;
        font-size: 13px;
        z-index: 1;
        line-height: 40px;
        height: 40px;
        box-shadow: -2px 2px 3px rgba(0, 0, 0, .3);
        text-align: center;
        top: 50%;
        margin-top: -20px;
        border-radius: 4px 4px 0 4px;
        right: 60px;
        margin-right: 10px;
        background-color: #007bff;
    }

    &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        bottom: 10px;
        left: -10px;
        box-shadow: -2px 2px 3px rgba(0, 0, 0, .3);
        border-top: 5px solid transparent;
        border-right: 5px solid transparent;
        border-left: 5px solid #007bff;
        border-bottom: 5px solid #007bff;
    }

    svg {
        margin: auto;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
}

.switch-tooltip {
    p {
        color: #000 !important;
        font-size: 12px;
        margin: 8px 0;
    }
}

.always-btn {
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 5px;
    color: #989a9f;
    padding: 12px;
    outline: none;
    margin: 0 12px 15px 0;

    &.active {
        background-color: #007bff;
        color: #fff;
    }
}
