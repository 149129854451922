.ecommerce-title {
    margin: 10px 0;
    display: flex;
    align-items: center;

    .svg-icon {
        margin-right: 8px;
        height: 22px;
        width: 22px;
    }
}

.ecommerce-tab {
    padding: 15px;
    border-radius: 7px;
    box-shadow: 10px 10px 20px rgb(0 0 0 / 8%);
    border: 1px solid rgba(51, 51, 51, .1);
    transition: all .25s ease;
    width: 300px;
    margin: 10px 15px 15px 10px;
    cursor: pointer;

    &:hover {
        transform: scale(1.05);
        box-shadow: 20px 20px 30px rgb(0 0 0 / 14%);
    }

    svg {
        width: 40px;
        height: 40px;
    }

    .header {
        font-size: 14px;
        font-weight: bold;
        margin: 10px 0;
    }

    .tip {
        color: $Secondary2ButtonTextColor;
        font-size: 11px;
        margin-bottom: 10px;
    }
}

.ecommerce-settings {
    .header {
        margin-top: 10px;
        padding: 10px;
        background-color: #ececec;
        display: flex;
        flex-direction: row;

        svg {
            width: 40px;
            height: 40px;
        }

        .title {
            padding: 0 20px;
            flex: 10;

            .name {
                font-size: 14px;
                font-weight: bold;
            }
        }

        .secondary-2-button {
            margin-right: 10px;
        }
    }

    .info {
        display: flex;
        padding: 10px;

        .article {
            min-width: 70%;
            max-width: 70%;
        }

        .details {
            min-width: 30%;
            max-width: 30%;

            .panel {
                margin-top: 15px;

                .head {
                    margin-bottom: 5px;
                }
            }

            .link {
                svg {
                    width: 16px;
                    height: 16px;
                    vertical-align: middle;
                    fill: $LinkColor;
                    margin-right: 5px;
                    opacity: .7;
                }
            }

            .tag-item {
                background: #ddebff;
                border-radius: 5px;
                color: #2b78e4;
                display: inline-block;
                font-size: 13px;
                font-weight: 400;
                margin: 0 4px 5px 0;
                padding: 4px 8px;
            }
        }
    }
}