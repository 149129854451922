.flex {
    display: flex;
}

.flex-space-between {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.pull-right {
    float: right;
}

.pull-left {
    float: left;
}

.text-center {
    text-align: center;
}

.table {
    width: 100%;

    thead {
        tr {
            height: 50px;
        }

        th {
            padding: 15px;
            font-weight: 500;
            text-align: left;
            min-height: 50px;
        }
    }

    tbody {
        tr {
            transition: .2s;

            &:hover {
                //background-color: #eee;
                background-color: rgba(0, 0, 0, .2) !important;
            }
        }

        td {
            padding: 10px 15px;
            min-height: 50px;
            white-space: nowrap;
            //max-width: 115px;

            .md-button {
                min-width: auto;
            }

            .md-icon-button {
                height: auto;
                width: auto;
                padding: 0;
                margin: 0;
            }
        }
    }
}

table.md-table td.md-cell {
    border-top: none;
    //word-break: break-all;
}

.text-red {
    color: #e24b5e !important;
}

.user-avatar {
    border-radius: 50%;
    object-fit: cover;
}
