@import 'mixin';

.tse-scrollable .tse-scrollbar {
    z-index: -1;

    .drag-handle {
        width: 0; // hidden scrollbar custom
    }
}

.setting-form {
    margin: 15px 0 0;

    .form-row {
        margin-right: -7.5px;
        margin-left: -7.5px;
        align-items: center;
        display: flex;
        flex-wrap: wrap;
    }

    .primary-button {
        font-size: 14px;
    }

    .form-group {
        margin-bottom: 10px;
        flex-grow: 1;
        position: relative;

        &.last {
            margin-bottom: 0;
        }

        label {
            color: #656a72;
            font-size: 16px;
            font-weight: 600;
            line-height: 1.7;
            margin-bottom: 2px;
            display: inline-block;
        }
    }
}

.side-menu {
    background-color: $MainMenuBlueColor;
    height: 100%;
    max-height: 100%;
    text-align: center;
    width: 110px;

    .scrollable-content {
        margin-right: 12px;
    }

    .menu-item {
        display: flex;
        position: relative;

        &:first-child {
            display: block;
            height: 62px;
            padding-top: 12px;
            border-bottom: 1px solid #3436C5;

            div, svg {
                opacity: 1;
            }

            svg {
                width: 36px;
                height: 33px;
            }

            .nav-item:after {
                display: none;
            }
        }

        &:hover, &.active {
            a {
                opacity: 1;
            }
        }

        &.active a::after {
            content: ' ';
            position: absolute;
            width: 14px;
            height: 14px;
            top: 35px;
            right: -12px;
            background-color: $MainMenuBlueColor;
            border: solid 5px #fff;
            border-radius: 50%;
            z-index: 1;
        }

        div {
            font-size: 12px;
            color: #fff;
        }
    }

    a {
        padding: 20px 0;
        width: 100%;
        text-transform: capitalize;
        opacity: .8;

        .menu-icon {
            width: 26px;
            height: 26px;
            fill: #fff;
            margin-bottom: 8px;
        }

        .badge {
            animation: opacity 2s infinite;
            background: #ff9900;
            border-radius: 50%;
            height: 6px;
            position: absolute;
            right: 38px;
            top: 22px;
            width: 6px;
        }
    }
}

@keyframes opacity {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.apps-popup {
    background-color: #fff;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, .15);
    font-size: 14px;
    height: auto;
    margin-top: 5px;
    position: fixed;
    right: 64px;
    transition: all .5s;
    width: 250px;
    z-index: 1000;

    @keyframes apps-popup-forward {
        0% {
            transform: scale(0);
            transform-origin: 20% 80%;
        }
        75% {
            transform: scale(1.1);
            transform-origin: 20% 80%;
        }
        100% {
            transform: scale(1);
            transform-origin: 20% 80%;
        }
    }

    @keyframes apps-popup-backward {
        0% {
            transform: scale(1);
            transform-origin: 20% 80%;
        }
        75% {
            transform: scale(1.1);
            transform-origin: 20% 80%;
        }
        100% {
            transform: scale(0);
            transform-origin: 20% 80%;
        }
    }

    &.user {
        top: 60px;
        right: 20px;
        margin: 0;
        padding: 15px;
        position: absolute;
        width: 235px;
        border-radius: 10px;
    }

    &.chat {
        border-radius: 5px;
        position: absolute;
        padding: 10px;
        left: 25px;
        bottom: 20px;
    }

    &.guest-popup {
        top: 45px;
        left: 0;
        position: absolute;
    }

    &.group-chat {
        left: 50px;
        top: 58px;
    }

    &.assign {
        border-radius: 5px;
        padding: 10px;
        position: absolute;
        right: 10px;
        left: auto;
        top: 40px;

        h4 {
            color: #231f20;
            text-align: center;
        }
    }

    &.reply {
        width: 330px;

        .search-input-chat {
            box-sizing: border-box;
            padding: 0 10px;
        }

        h4 {
            color: #231f20;
            text-align: center;
        }

        .popup-list-item {
            padding: 5px 10px 4px;
            border-radius: 5px;
            margin: 0 !important;

            &:hover {
                background: #dfebfd;
            }
        }
    }

    .popup-list {
        margin-top: 5px;
    }

    .popup-list-item {
        cursor: pointer;
        padding: 5px 10px 4px;
        border-radius: 5px;
        position: relative;

        &:hover {
            background: #dfebfd;
        }

        &:last-child {
            margin-bottom: 0;
        }

        .user-status-icon {
            position: absolute;
            left: 30px;
            bottom: 3px;
            height: 8px;
            width: 8px;
        }

        img {
            margin-right: 7px;
            vertical-align: middle;
        }

        .md-button {
            padding: 0 !important;

            &:hover {
                background: none !important;
            }
        }
    }

    h4 {
        font-weight: 500;
        margin-bottom: 10px;
    }

    .avatar-block {
        position: relative;
        margin-right: 20px;

        img {
            margin: 0;
        }
    }

    .md-list-item-inner {
        overflow: hidden;
        padding-left: 0;
        white-space: nowrap;
        width: 90%;
    }

    .shortcut, .text-reply {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .text-reply {
        width: 65%;
    }

    .link-status {
        color: #0095cf;
        cursor: pointer;
    }

    .shortcut {
        padding-right: 4%;
        width: 30%;
    }

    .search-input-chat {
        margin-bottom: 10px !important;

        .md-input {
            border: 1px solid #eee;
            border-radius: 6px;
            background: rgba(0, 0, 0, .03);
            padding: 0 10px;
        }
    }

    h3 {
        cursor: default;
        font-size: 15px;
        margin: 0 0 5px;
        text-align: center;
    }

    md-menu-content {
        padding: 0;
    }
}

.user-status-icon {
    border: 1px solid #fff;
    border-radius: 50%;
    height: 10px;
    margin-right: 5px;
    display: inline-block;
    width: 10px;

    &.online {
        background: green;
    }

    &.away {
        background: #FFBF00;
    }

    &.offline {
        background: #cacaca;
    }
}

.user-status {
    position: absolute;
    width: 10px;
    border-radius: 50%;
    top: 28px;
    right: 0;
    border: 1px solid #fff;
    height: 10px;

    &.online {
        background: green;
    }

    &.away {
        background: #FFBF00;
    }

    &.offline {
        background: #cacaca;
    }
}

.ember-basic-dropdown-content {
    md-menu-content {
        max-height: 330px;
    }
}

